<template>
  <div class="li-schedulingApply">
    <div class="form-workflow" sign="BigScreen">
      <p class="text-title">{{ form.dept }} {{ form.month }} 员工休息表</p>
      <el-table
        class="table-arrange"
        :data="form.arrange"
        border
        v-if="form.arrange"
        :height="action === 'print' ? 'unset' : '520'"
        style="width: 92%"
        :span-method="objectSpanMethod"
      >
        <el-table-column fixed label="序号" width="35px" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          fixed
          label="姓名"
          prop="name"
          width="45px"
          align="center"
        >
          <template slot-scope="scope">
            <p @click="handleSelectAdjust(scope.row)" class="btnAdjust">
              {{ scope.row.name }}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          width="32px"
          align="center"
          v-for="(item, index) in form.dateScope"
          :key="item"
        >
          <template slot="header">
            <!-- 日期 -->
            <p class="text-day">{{ getDay(item) }}</p>
            <p class="text-week">{{ getWeek(item) }}</p>
          </template>
          <template slot-scope="scope">
            <tl-dict-tag
              style="background-color: #ffffff; width: 100%; height: 35px"
              v-if="scope.row.staffCode + ':' + index !== indexStaffCode"
              :code="scope.row.staffCode + ':' + index"
              :options="dict.zjwy_holiday_type"
              :value="scope.row.day[index]"
              @click.native="
                handleSelectStaffCode(scope.row.staffCode + ':' + index)
              "
              class="tag"
            />
            <el-select
              v-if="scope.row.staffCode + ':' + index === indexStaffCode"
              class="select-leave"
              tl-dict-select
              v-model="scope.row.day[index]"
              clearable
              placeholder=""
              :disabled="
                index < scope.row.dayPadPre
                  ? scope.row.dayPadPreExist !== 0
                  : disabled || forbidden
              "
              @change="handleUpdate"
            >
              <!-- <el-option label="空" :value="null" /> -->
              <el-option
                v-for="dict in dict.zjwy_holiday_type"
                :key="dict.dictValue"
                :label="dict.dictLabel"
                :value="dict.dictValue"
              />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column
          label="备注"
          prop="remark"
          align="center"
          width="65px"
        />
        <el-table-column label="签名确认" align="center" />
      </el-table>
      <p class="text-hint">
        说明：上午、下午表示上午、下午休息半天、休息表示休息一天，年假、病假、事假表示休年假、病假、事假，上补表示上午补休，下补表示下午补休，全补表示全天补休,
        <br />
        置空表示上班，补班表示休息日全日补上班，调休表示工作日调整为全日休息,法假表示法定节假日,
        年上表示上午休半天年假，年下表示下午休半天年假
      </p>
      <!-- <br class="text-hint" /> -->
      <div class="box-operation">
        <span class="text-info">制表：{{ form.creator }}</span>
        <span class="text-info">部门/项目经理：{{ form.deptManager }}</span>
      </div>
      <p class="text-history">休假排班变更历史</p>
      <el-timeline>
        <el-timeline-item
          v-for="(activity, index) in form.adjustHistory
            ? form.adjustHistory
            : []"
          :key="index"
        >
          {{ activity }}
        </el-timeline-item>
      </el-timeline>
    </div>
    <li-modal ref="liModal" />
    <el-dialog title="调整休假规律" :visible.sync="visible" width="600px">
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="ruleRules"
        label-width="100px"
      >
        <el-col :span="24">
          <el-form-item label="职务" prop=" staffPostName">
            <el-input v-model="ruleForm.staffPostName" readonly> </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="排版规则" prop=" ruleName">
            <el-input v-model="ruleForm.ruleName" readonly> </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="休假天数" prop=" ruleDay">
            <el-input v-model="ruleForm.ruleDay" readonly> </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="休假规则" prop=" ruleType">
            <el-input v-model="ruleForm.ruleType" readonly> </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="调整类型" prop="restType">
            <tl-dict-select
              style="width: 100%"
              v-model="ruleForm.restType"
              dict="zjwy_rest_type"
              placeholder="请输入"
              clearable
            />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="调整休假日" prop="restDate">
            <el-input
              v-model="ruleForm.restDate"
              clearable
              placeholder="休假时间选择每月、每周时必填，数字：1、2、3、4、5、6、7在每月时代表日期，在每周时代表周一到周日"
              @change="handleChangeRestDate"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="handleSubmitAdjust">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { listDeptMember } from "@/request/system";
export default {
  name: "LiSchedulingApply",
  dicts: ["zjwy_holiday_type"],
  props: {
    config: {
      typeof: Object,
      default() {
        return {};
      },
    },
    value: {
      typeof: Array,
      default() {
        return {};
      },
    },
    disabled: {
      typeof: Boolean,
      default: false,
    },
    action: {
      typeof: String,
      default: undefined,
    },
  },
  computed: {
    forbidden() {
      if (this.normalizer.fields) {
        const found = this.normalizer.fields.find(
          (element) => element.__vModel__ === "arrange"
        );
        return found && found.disabled;
      }
      return false;
    },
  },
  data() {
    return {
      indexStaffCode: undefined,
      normalizer: {},
      form: {
        dateScope: [],
        adjustHistory: undefined,
        arrange: [],
        createTime: undefined,
        creator: undefined,
        dept: undefined,
        deptId: undefined,
        deptManager: undefined,
        month: undefined,
      },
      rules: {},
      visible: false,
      ruleForm: {
        staffCode: undefined,
        restType: undefined,
        restDate: undefined,
      },
      ruleRules: {
        restType: [
          { required: true, message: "请选择调整类型", trigger: "blur" },
        ],
        restDate: [
          { required: true, message: "请输入调整休假日", trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    value(v) {
      this.form = { ...v };
    },
  },
  methods: {
    handleChangeRestDate(v) {
      let days = v.split("、");
      for (let index in days) {
        if (days[index].length === 1) {
          days[index] = "0" + days[index];
        }
      }
      this.ruleForm.restDate = days.join("、");
    },
    handleSelectStaffCode(indexStaffCode) {
      this.indexStaffCode = indexStaffCode;
    },
    objectSpanMethod({ columnIndex }) {
      if (columnIndex === this.form.dateScope.length + 3) {
        return {
          rowspan: 1,
          colspan: 1,
        };
      }
    },
    getDay(value) {
      return new Date(value).format("dd");
    },
    getWeek(value) {
      let week = {
        0: "日",
        1: "一",
        2: "二",
        3: "三",
        4: "四",
        5: "五",
        6: "六",
      };
      return week[new Date(value).getDay()];
    },
    init() {
      this.normalizer = JSON.parse(this.config.formJson);
      const form = {};
      const rules = {};
      this.normalizer.fields.forEach((element) => {
        form[element.__vModel__] = undefined;
        if (element.__config__.required) {
          rules[element.__vModel__] = [
            { required: true, message: element.placeholder, trigger: "blur" },
          ];
        }
        const find = this.config.permission.find(
          (item) => item.field === element.__vModel__
        );
        if (find && find.permission === "r") {
          element.disabled = true;
        }
      });
      this.form = { ...form, ...this.value };
      this.rules = rules;
      this.handleUpdate();
      if (this.form.process && this.form.process.proInsStatus === "2") {
        listDeptMember({
          deptId: this.form.deptId,
          month: this.form.month,
          lastArrange: this.form.arrange,
        }).then((res) => {
          this.form = {
            ...this.form,
            ...res.data,
          };
          this.handleUpdate();
        });
      } else if (
        this.config.query &&
        this.config.query.deptId &&
        this.config.query.month
      ) {
        listDeptMember({
          deptId: this.config.query.deptId,
          month: this.config.query.month,
        }).then((res) => {
          this.form = {
            ...this.form,
            ...res.data,
          };
          this.handleUpdate();
        });
      }
    },

    handleUpdate() {
      if (!this.form.arrange) {
        return;
      }
      this.form.arrange.map((item, arrangeIndex) => {
        item.day.map((res, dayIndex) => {
          if (res === "empty") {
            this.form.arrange[arrangeIndex].day[dayIndex] = null;
          }
        });
      });
      this.$emit("input", this.form);
    },
    validate(functionValidate) {
      functionValidate(true);
    },
    handleSelectAdjust(row) {
      this.ruleForm = {
        staffPostName: row.staffPostName,
        staffCode: row.staffCode,
        restType: undefined,
        restDate: undefined,
        ruleDay: row.ruleDay,
        ruleName: row.ruleName,
        ruleType: row.ruleType,
      };
      this.visible = true;
    },
    handleSubmitAdjust() {
      if (
        (this.ruleForm.restType === "weekly" ||
          this.ruleForm.restType === "monthly") &&
        !this.ruleForm.restDate
      ) {
        this.$message.error("请输入休假日");
        return;
      }
      const foundIndex = this.form.arrange.findIndex(
        (element) => element.staffCode === this.ruleForm.staffCode
      );
      const foundDay = JSON.parse(
        JSON.stringify(this.form.arrange[foundIndex].day)
      );
      this.form.dateScope.forEach((element, index) => {
        if (this.ruleForm.restType === "monthly") {
          if (
            this.ruleForm.restDate.indexOf(new Date(element).format("dd")) >= 0
          ) {
            // && new Date(element).format("yyyy-MM") === this.config.query.month;
            if (new Date(element).format("yyyy-MM") === this.form.month) {
              foundDay[index] = "rest";
            }
          }
        } else if (this.ruleForm.restType === "weekly") {
          let day = new Date(element).getDay();
          day === 0 ? (day = 7) : undefined;
          if (this.ruleForm.restDate.indexOf(day) >= 0) {
            if (new Date(element).format("yyyy-MM") === this.form.month) {
              foundDay[index] = "rest";
            }
          }
        }
      });
      this.form.arrange[foundIndex].day = foundDay;
      this.handleUpdate();
      this.visible = false;
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="less" scoped>
.li-schedulingApply {
  margin: 20px auto;

  .text-title {
    text-align: center;
    font-weight: bold;
    margin-bottom: 15px;
  }

  .text-hint {
    font-size: 14px;
    font-weight: bold;
    margin-top: 15px;
  }

  .table-arrange {
    :deep(.cell) {
      padding: 0;
      .btnAdjust {
        cursor: pointer;
        &:hover {
          color: #06a06c;
        }
      }

      .text-week {
        border-top: 2px solid #0a0808;
      }
    }
  }

  .select-leave {
    :deep(.el-input) {
      .el-input__inner {
        border: none;
        padding: 0;
        text-align: center;
      }

      &.is-disabled {
        .el-input__inner {
          background-color: unset;
          color: #333333;
        }
      }

      .el-input__suffix {
        display: none;
      }
    }
  }

  .box-operation {
    margin: 20px 0;

    .text-info {
      &:last-child {
        margin-left: 50px;
      }
    }
  }
  .text-history {
    position: relative;
    padding-left: 15px;
    margin-bottom: 15px;
    &::before {
      height: 10px;
      width: 10px;
      left: 0;
      top: 6px;
      position: absolute;
      background-color: #06a06c;
      content: "";
      border-radius: 10px;
    }
  }
  /deep/ .el-table td.el-table__cell {
    border-bottom: 0.5px solid #0a0808;
    border-right: 1px solid #0a0808;
  }
  /deep/ .el-table--border .el-table__cell {
    border-bottom: 0.5px solid #0a0808;
    border-right: 1.5px solid #0a0808;
    border-top: 0.3px solid #000;
  }
  /deep/ .el-table--border,
  .el-table--group {
    border: 1px solid #0a0808;
  }
}
</style>

/**
 * @author Zhong Li
 * @description Vue global mixin
 * @since 1.0.0
 * @version 1.0.0
 */
import Vue from "vue";
import { dictOptions } from "@/request/system";
Vue.mixin({
  data() {
    if (!this.$options || !(this.$options.dicts instanceof Array)) {
      return {};
    } else {
      let dict = {};
      this.$options.dicts.forEach((item) => {
        dict[item] = [];
      });
      return {
        dict: dict,
      };
    }
  },
  created() {
    if (this.$options && this.$options.dicts instanceof Array) {
      this.$options.dicts.forEach((item) => {
        dictOptions(item)
          .then((res) => {
            this.dict[item] = res.data;
          })
          .catch(() => {
            this.dict[item] = [];
          });
      });
    }
  },
});

<template>
  <component
    class="li-view"
    :is="component"
    :params="params"
    :query="query"
    @submit="handleSubmit"
    @cancel="handleCancel"
  />
</template>

<script>
export default {
  name: "LiView",
  props: {
    // 要显示的vue页面，只能是src/views目录下的vue文件 | type:String
    view: {
      type: String,
      default: undefined,
    },
    // 传给弹窗加载页面或组件的参数
    params: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      component: undefined, // 加载
      query: {},
    };
  },
  watch: {
    view(v) {
      if (v) {
        this.component = this.$utils.lazyLoadView(this.view);
      } else {
        this.component = undefined;
      }
    },
  },
  methods: {
    handleSubmit(data) {
      this.$emit("submit", data);
    },
    handleCancel(data) {
      this.$emit("cancel", data);
    },
  },
  mounted() {
    if (this.view) {
      const [component, query] = this.view.split("?");
      if (query) {
        query.split("&").forEach((item) => {
          this.query[item.split("=")[0]] = item.split("=")[1];
        });
      }
      this.component = this.$utils.lazyLoadView(component);
    }
  },
};
</script>

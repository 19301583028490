<template>
  <el-form-item
    :label="normalizer.__config__.label"
    :prop="normalizer.__vModel__"
    v-if="!normalizer.__config__.hidden"
  >
    <el-date-picker
      prefix-icon="none"
      v-model="model"
      :clearable="normalizer.clearable"
      :readonly="normalizer.disabled"
      :placeholder="normalizer.placeholder"
      :style="normalizer.style"
      :type="normalizer.type"
      :value-format="normalizer.format"
      @change="handleInput"
    />
  </el-form-item>
</template>

<script>
export default {
  name: "DatePickerModule",
  props: {
    normalizer: {
      typeof: Object,
      default() {
        return {};
      },
    },
    value: {
      type: [String, Number, Array, Date],
      default: undefined,
    },
  },
  data() {
    return {
      model: undefined,
    };
  },
  computed: {
    print() {
      return this.$route.query.action === "print";
    },
  },
  watch: {
    value(v) {
      this.model = v;
    },
  },
  methods: {
    handleInput(v) {
      this.$emit("update", { [this.normalizer.__vModel__]: v });
    },
  },
  mounted() {
    this.model = this.value;
    if (this.print && this.normalizer.printHide) {
      this.model = undefined;
    }
  },
};
</script>

<template>
  <div class="tl-staffSelect">
    <el-input :placeholder="placeholder" :value="model" readonly>
      <i
        v-if="!disabled"
        slot="prefix"
        class="el-input__icon el-icon-user-solid"
        style="cursor: pointer"
        @click="visible = true"
      />
    </el-input>
    <el-dialog
      title="请选择用户"
      :visible.sync="visible"
      width="1024px"
      :append-to-body="true"
    >
      <el-row>
        <div section-condition>
          <el-form
            label-width="50px"
            ref="condition"
            :model="condition"
            size="small"
            :inline="true"
          >
            <el-form-item label="部门" prop="deptId">
              <tl-dept-select
                style="width: 220px"
                v-model="condition.deptId"
                size="small"
              />
            </el-form-item>
            <el-form-item label="名称" prop="name">
              <el-input
                v-model="condition.name"
                placeholder="请输入姓名"
                clearable
              />
            </el-form-item>
            <el-form-item>
              <el-button
                type="search"
                icon="el-icon-search"
                size="mini"
                @click="handleQuery"
                >搜索</el-button
              >
              <el-button
                type="reset"
                icon="el-icon-refresh"
                size="mini"
                @click="handleResetQuery"
                >重置</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <el-col :span="24">
          <el-table :data="list" style="width: 100%">
            <el-table-column label="部门" align="center" prop="dept" />
            <el-table-column label="姓名" align="center" prop="name" />
            <el-table-column label="职务" align="center" prop="job" />
            <el-table-column label="入职时间" align="center" prop="hireDate" />
            <el-table-column label="操作" align="center" width="55px">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="text-edit"
                  @click="handleSelected(scope.row)"
                  >选择</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            pagination
            background
            :page-sizes="[10, 20, 50, 100]"
            :current-page="condition.pageNum"
            :page-size="condition.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          />
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { listWithoutRelate } from "@/request/archives";

export default {
  name: "TlStaffSelect",
  props: {
    value: {
      type: [String, Number],
      default: undefined,
    },
    label: {
      type: String,
      default: undefined,
    },
    placeholder: {
      type: String,
      default: "请选择",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      condition: {
        pageNum: 1,
        pageSize: 10,
        deptId: undefined,
        name: undefined,
      },
      list: [],
      total: 0,
      model: undefined,
      visible: false,
    };
  },
  watch: {
    value(v) {
      this.model = v;
    },
    model(v) {
      this.$emit("input", v);
    },
  },
  methods: {
    query(pageNum, pageSize) {
      this.condition.pageNum = pageNum;
      this.condition.pageSize = pageSize;
      this.loading = true;
      listWithoutRelate(this.condition).then((res) => {
        this.loading = false;
        this.list = res.rows;
        this.total = res.total;
      });
    },
    handleCurrentChange(pageNum) {
      this.query(pageNum, this.condition.pageSize);
    },
    handleSizeChange(pageSize) {
      this.query(1, pageSize);
    },
    handleSelected(row) {
      this.model = row.name;
      this.$emit("on-selected", row.name, row);
      this.visible = false;
    },
    handleQuery() {
      this.query(1, this.condition.pageSize);
    },
    handleResetQuery() {
      this.$refs["condition"].resetFields();
      this.query(1, 10);
    },
  },
  mounted() {
    this.model = this.value;
    this.query(1, 10);
  },
};
</script>

<template>
  <el-form-item
    :label="normalizer.__config__.label"
    :prop="normalizer.__vModel__"
    v-if="!normalizer.__config__.hidden"
  >
    <treeselect
      ref="treeselect"
      v-model="model"
      :placeholder="normalizer.placeholder"
      :options="deptData"
      :normalizer="format"
      :disabled="normalizer.disabled"
      @input="handleChange"
      @select="handleSelect"
    />
  </el-form-item>
</template>

<script>
import { listInsideOrganization } from "@/request/system";
import Treeselect from "@riophae/vue-treeselect";
import { handleTree } from "@/utils/utils";

export default {
  name: "DeptSelect",
  components: { Treeselect },
  props: {
    normalizer: {
      typeof: Object,
      default() {
        return {};
      },
    },
    value: {
      type: [Number, String],
      default: undefined,
    },
  },
  data() {
    return {
      deptData: [],
      model: undefined,
    };
  },
  watch: {
    value(v) {
      this.model = v;
    },
    selectValue(v) {
      this.$emit("input", v);
    },
  },
  methods: {
    format(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.deptId,
        label: node.deptName,
        children: node.children,
      };
    },
    queryDeptTreeData() {
      listInsideOrganization().then((res) => {
        let treeData = handleTree(res.data, "deptId", "parentId", "children");
        if (Array.isArray(treeData) && treeData[0]) {
          treeData[0].isDisabled = this.disabled;
        }
        this.deptData = treeData;
      });
    },
    handleChange(value) {
      setTimeout(() => {
        this.$emit("update", {
          [this.normalizer.__vModel__]: value,
        });
      }, 200);
    },
    // gaoyan-2022-11-28改动，添加了dept
    handleSelect(node) {
      setTimeout(() => {
        this.$emit("update", {
          dept: node.treeName,
        });
      }, 200);
    },
  },
  mounted() {
    this.queryDeptTreeData();
    this.model = this.value;
  },
};
</script>
<style lang="less" scoped>
:deep(.vue-treeselect__control) {
  height: 45px;
  .vue-treeselect__single-value {
    color: #333333;
    line-height: 45px;
  }
}
:deep(.vue-treeselect__menu-container) {
  .vue-treeselect__label {
    font-size: 16px;
    margin: 4px 0;
  }
}
</style>

/**
 * @author Zhong Li
 * @description Directive
 * @since 1.0.0
 * @version 1.0.0
 */
import Vue from "vue";
import store from "@/store";
Vue.directive("permission", {
  inserted: function (el, bind) {
    const { value } = bind;
    const allPermission = "*:*:*";
    const permissions =
      store.state.auth.loginInfo && store.state.auth.loginInfo.permissions;

    permissions.some((permission) => {
      return allPermission === permission || value.includes(permission);
    });

    const hasPermissions = permissions.some((permission) => {
      return allPermission === permission || value.includes(permission);
    });
    if (!hasPermissions) {
      el.remove();
    }
  },
});

<template>
  <div class="layout-admin">
    <div class="box-sideBar">
      <div class="box-title left">
        <div class="box-top clear">
          <p class="text-titleCN right">业务运营支撑系统</p>
          <i class="icon-system right" />
        </div>
        <p class="text-titleEN">BUSINESS OPERATION SUPPORT SYSTEM</p>
      </div>
      <side-bar />
    </div>
    <div class="box-main">
      <div class="box-head clear">
        <p class="text-welcome left">
          欢迎你, {{ loginInfo && loginInfo.user && loginInfo.user.nickName }}
        </p>

        <div class="box-user right clear" @click="visibleMenu = !visibleMenu">
          <i
            v-if="!loginInfo.user.avatar"
            class="icon-personalInfo left image-avatar"
          />
          <img
            v-if="loginInfo.user.avatar"
            class="image-avatar left"
            :src="loginInfo.user.avatar"
          />
          <p class="text-personalInfo left">个人信息</p>
        </div>
        <div class="box-user right clear" @click="handleLogout">
          <i class="icon-log_out left image-avatar" />
          <p class="text-personalInfo left">退出登陆</p>
        </div>
        <ul class="list-menu" v-if="visibleMenu">
          <li>
            <router-link class="link-item" to="/personal/data"
              >个人资料</router-link
            >
          </li>
          <li>
            <router-link class="link-item" to="/personal/password"
              >修改密码</router-link
            >
          </li>
          <li>
            <router-link class="link-item" to="/personal/avatar"
              >修改头像</router-link
            >
          </li>
          <!-- <li>
            <a class="link-item" href="javascript:void(0)" @click="handleLogout"
              >退出登录</a
            >
          </li> -->
        </ul>
      </div>
      <div class="box-body">
        <tag-view />
        <router-view class="box-page" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { logout } from "@/request/login";
import { removeAuthToken } from "@/cookie/auth";
import SideBar from "./modules/SideBar";
import TagView from "./modules/TagView";
export default {
  name: "LayoutAdmin",
  components: {
    SideBar,
    TagView,
  },
  computed: {
    ...mapState({
      loginInfo: (state) => state.auth.loginInfo,
    }),
  },
  watch: {
    $route() {
      this.visibleMenu = false;
    },
  },
  data() {
    return {
      visibleMenu: false,
    };
  },
  methods: {
    handleLogout() {
      this.$confirm("登出系统?", {
        type: "warning",
      })
        .then(() => {
          logout().then(() => {
            removeAuthToken();
            this.$store.dispatch("auth/clearLoginInfo");
            this.$router.push("/login");
          });
        })
        .catch(() => {});
    },
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.layout-admin {
  position: relative;
  .box-sideBar {
    width: 250px;
    background: #3e4744;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;

    .box-title {
      width: 100%;
      height: 80px;
      background-color: #06a06c;

      .box-top {
        padding-top: 15px;
        .icon-system {
          margin-right: 15px;
        }
        .text-titleCN {
          color: #ffffff;
          font-size: 18px;
          display: inline-block;
          letter-spacing: 5px;
          line-height: 32px;
        }
      }

      .text-titleEN {
        color: #ffffff;
        font-size: 12px;
        text-align: right;
      }
    }
  }

  .box-main {
    margin-left: 250px;
    width: calc(100% - 250px);

    .box-head {
      background-color: #06a06c;
      height: 80px;
      position: fixed;
      top: 0;
      left: 250px;
      right: 0;
      width: calc(100% - 250px);
      z-index: 999;

      .text-welcome {
        color: #ffffff;
        font-size: 18px;
        margin-top: 40px;
        margin-left: 25px;
      }

      .box-user {
        margin: 26px 40px 26px 0;
        line-height: 28px;
        cursor: pointer;

        .image-avatar {
          width: 28px;
          height: 28px;
          border-radius: 50%;
        }
        .text-personalInfo {
          color: #ffffff;
          font-size: 18px;
          margin-left: 5px;
        }

        &:hover {
          .text-personalInfo {
            color: #80e4da;
          }
        }
      }

      .list-menu {
        position: absolute;
        right: 0;
        top: 80px;
        width: 180px;
        text-align: center;
        background-color: #c0f1ed;
        opacity: 0.7;
        z-index: 100;

        li {
          list-style-type: none;

          .link-item {
            padding: 15px 0;
            display: block;
            color: #666666;
            text-decoration: none;
          }

          &:hover {
            .link-item {
              background-color: #80e4da;
              color: #333333;
            }
          }
        }
      }
    }

    .box-body {
      padding-top: 80px;
      .box-page {
        padding: 5px;
        width: calc(100% - 10px);
      }
    }
  }
}
@media screen and (max-width: 1440px) {
  .layout-admin {
    position: relative;
    .box-sideBar {
      width: 180px;
      background: #3e4744;
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;

      .box-title {
        width: 100%;
        height: 80px;
        background-color: #06a06c;

        .box-top {
          padding-top: 15px;
          .icon-system {
            margin-right: 15px;
            width: 24px;
          }
          .text-titleCN {
            color: #ffffff;
            font-size: 12px;
            display: inline-block;
            letter-spacing: 5px;
            line-height: 32px;
          }
        }

        .text-titleEN {
          color: #ffffff;
          font-size: 12px;
          text-align: right;
        }
      }
      .comp-sideBar {
        width: 180px;
      }
    }

    .box-main {
      margin-left: 180px;
      width: calc(100% - 180px);

      .box-head {
        background-color: #06a06c;
        height: 80px;
        position: fixed;
        top: 0;
        left: 180px;
        right: 0;
        width: calc(100% - 180px);
        z-index: 999;

        .text-welcome {
          color: #ffffff;
          font-size: 18px;
          margin-top: 40px;
          margin-left: 25px;
        }

        .box-user {
          margin: 26px 40px 26px 0;
          line-height: 28px;
          cursor: pointer;

          .image-avatar {
            width: 28px;
            height: 28px;
            border-radius: 50%;
          }
          .text-personalInfo {
            color: #ffffff;
            font-size: 18px;
            margin-left: 5px;
          }

          &:hover {
            .text-personalInfo {
              color: #80e4da;
            }
          }
        }

        .list-menu {
          position: absolute;
          right: 0;
          top: 80px;
          width: 180px;
          text-align: center;
          background-color: #c0f1ed;
          opacity: 0.7;
          z-index: 100;

          li {
            list-style-type: none;

            .link-item {
              padding: 15px 0;
              display: block;
              color: #666666;
              text-decoration: none;
            }

            &:hover {
              .link-item {
                background-color: #80e4da;
                color: #333333;
              }
            }
          }
        }
      }

      .box-body {
        padding-top: 80px;
        .box-page {
          padding: 5px;
          width: calc(100% - 10px);
        }
      }
    }
  }
}
</style>

<template>
  <el-scrollbar class="comp-sideBar">
    <el-menu
      background-color="#4E5A56"
      text-color="#ffffff"
      active-text-color="#23926E"
      :default-active="activePage"
    >
      <side-bar-item
        v-for="(route, index) in routes"
        :item="route"
        :key="route.path + index + new Date().getTime()"
      />
    </el-menu>
  </el-scrollbar>
</template>

<script>
import { mapState } from "vuex";
import SideBarItem from "./modules/SideBarItem/index";

export default {
  name: "SideBar",
  components: {
    SideBarItem,
  },
  computed: {
    ...mapState({
      routes: (state) => state.auth.routes,
    }),
    activePage() {
      return this.$route.path;
    },
  },
};
</script>

<style scoped lang="less">
.comp-sideBar {
  width: 250px;
  height: calc(100% - 80px);

  :deep(.el-scrollbar__wrap) {
    overflow-x: hidden;
  }

  .el-menu {
    border: none;
  }
}
</style>

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

/** ElementUI */
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

Vue.use(ElementUI);

/** Vue-Treeselect */
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

/** CSS modules */
import "./assets/style/index.less";

/** JavaScript modules */
import "./assets/js/index.js";

import "./install/permission";
import "./install/mixin";
import "./install/directive";
import "./install/components";
import "./install/prototype";

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

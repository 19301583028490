/**
 * @author Zhong Li
 * @description Prototype
 * @since 1.0.0
 * @version 1.0.0
 */
import Vue from "vue";

Vue.prototype.$utils = {
  /**
   * Ceate UUID
   * @returns A new UUID
   */
  createUUID: function () {
    function S4() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }

    return (
      S4() +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      S4() +
      S4()
    );
  },

  /**
   * Lazy load vue component
   * @param {string} view - Vue path
   * @returns Vue component
   */
  lazyLoadView: function (view) {
    if (process.env.NODE_ENV === "development") {
      return (resolve) => require([`@/views/${view}`], resolve);
    } else {
      return () => import(`@/views/${view}`);
    }
  },

  /**
   * Deep copy
   * @param {object} obj - Object
   * @returns A new obejct
   */
  deepCopy: function (obj) {
    let v = null;
    if (Object.prototype.toString.call(obj) === "[object Object]") {
      v = new Object(null);
      for (const k in obj) {
        switch (Object.prototype.toString.call(obj[k])) {
          case "[object Object]":
            v[k] = this.deepCopy(obj[k]);
            break;
          case "[object Array]":
            v[k] = obj[k].concat();
            break;
          default:
            v[k] = obj[k];
            break;
        }
      }
    } else if (Object.prototype.toString.call(obj) === "[object Array]") {
      v = [];
      for (const index in obj) {
        v[index] = obj[index];
      }
    }
    return v;
  },

  /**
   * Build the tree structure data
   * @param {*} data Data source
   * @param {*} id The id field defaults to 'id'
   * @param {*} parentId The parent node field defaults 'parentId'
   * @param {*} children Child node field defaults 'children'
   */
  buildTree: function (data, id, parentId, children) {
    let config = {
      id: id || "id",
      parentId: parentId || "parentId",
      childrenList: children || "children",
    };

    var childrenListMap = {};
    var nodeIds = {};
    var tree = [];

    for (let d of data) {
      let parentId = d[config.parentId];
      if (childrenListMap[parentId] == null) {
        childrenListMap[parentId] = [];
      }
      nodeIds[d[config.id]] = d;
      childrenListMap[parentId].push(d);
    }

    for (let d of data) {
      let parentId = d[config.parentId];
      if (nodeIds[parentId] == null) {
        tree.push(d);
      }
    }

    for (let t of tree) {
      adaptToChildrenList(t);
    }

    function adaptToChildrenList(o) {
      if (childrenListMap[o[config.id]] !== null) {
        o[config.childrenList] = childrenListMap[o[config.id]];
      }
      if (o[config.childrenList]) {
        for (let c of o[config.childrenList]) {
          adaptToChildrenList(c);
        }
      }
    }
    return tree;
  },

  /**
   * Download blob
   * @param {Blob} blobSource - Blob source
   * @param {Blob} name - Download name
   */
  downloadBlob(blobSource, name) {
    let a = document.createElement("a");
    let blob = new Blob([blobSource], { type: blobSource.type });
    a.href = URL.createObjectURL(blob);
    if (!name) {
      a.setAttribute("download", new Date().getTime());
    } else {
      a.setAttribute("download", name);
    }
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  },

  addDateRange(params, dateRange, propName) {
    let search = params;
    search.params =
      typeof search.params === "object" &&
      search.params !== null &&
      !Array.isArray(search.params)
        ? search.params
        : {};
    dateRange = Array.isArray(dateRange) ? dateRange : [];
    if (typeof propName === "undefined") {
      search.params["beginTime"] = dateRange[0];
      search.params["endTime"] = dateRange[1];
    } else {
      search.params["begin" + propName] = dateRange[0];
      search.params["end" + propName] = dateRange[1];
    }
    return search;
  },

  // 日期格式化
  parseTime(time, pattern) {
    if (arguments.length === 0 || !time) {
      return null;
    }
    const format = pattern || "{y}-{m}-{d} {h}:{i}:{s}";
    let date;
    if (typeof time === "object") {
      date = time;
    } else {
      if (typeof time === "string" && /^[0-9]+$/.test(time)) {
        time = parseInt(time);
      } else if (typeof time === "string") {
        time = time
          .replace(new RegExp(/-/gm), "/")
          .replace("T", " ")
          .replace(new RegExp(/\.[\d]{3}/gm), "");
      }
      if (typeof time === "number" && time.toString().length === 10) {
        time = time * 1000;
      }
      date = new Date(time);
    }
    const formatObj = {
      y: date.getFullYear(),
      m: date.getMonth() + 1,
      d: date.getDate(),
      h: date.getHours(),
      i: date.getMinutes(),
      s: date.getSeconds(),
      a: date.getDay(),
    };
    const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
      let value = formatObj[key];
      // Note: getDay() returns 0 on Sunday
      if (key === "a") {
        return ["日", "一", "二", "三", "四", "五", "六"][value];
      }
      if (result.length > 0 && value < 10) {
        value = "0" + value;
      }
      return value || 0;
    });
    return time_str;
  },
};

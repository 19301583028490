<template>
  <el-submenu v-if="!item.meta.hidden && item.meta.group" :index="item.path">
    <template slot="title">
      <i class="icon" v-if="item.meta.icon" :class="item.meta.icon" />
      <span slot="title"> {{ item.meta.title }}</span>
    </template>

    <side-bar-item
      v-for="child in item.children"
      :item="child"
      :key="child.path"
    />
  </el-submenu>
  <router-link class="route-link" v-else-if="!item.meta.hidden" :to="item.path">
    <el-menu-item :index="item.redirect ? item.redirect : item.path">
      <i class="icon" v-if="item.meta.icon" :class="item.meta.icon" />
      <span slot="title"> {{ item.meta.title }}</span>
    </el-menu-item>
  </router-link>
</template>

<script>
export default {
  name: "SideBarItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="less" scoped>
.icon {
  width: 20px;
  height: 20px;
}
.route-link {
  text-decoration: none;
}
</style>

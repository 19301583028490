<template>
  <div>
    <el-form-item
      :label="normalizer.__config__.label"
      :prop="normalizer.__vModel__"
      v-if="!normalizer.__config__.hidden"
    >
      <el-select
        v-model="model"
        :clearable="normalizer.clearable"
        :disabled="normalizer.disabled"
        :filterable="normalizer.filterable"
        :multiple="normalizer.multiple"
        :placeholder="normalizer.placeholder"
        :style="normalizer.style"
        @change="handleInput"
      >
        <el-option
          v-for="item in options"
          :key="item.dictValue"
          :label="item.dictLabel"
          :value="item.dictValue"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <li-modal ref="liModal" />
  </div>
</template>

<script>
import { getData } from "@/request/system";
export default {
  name: "SelectModule",
  props: {
    normalizer: {
      typeof: Object,
      default() {
        return {};
      },
    },
    value: {
      type: [String, Number, Array, Date],
      default: undefined,
    },
  },
  data() {
    return {
      model: undefined,
      options: [],
      modalConfig: {
        width: "1024px",
        center: true,
        hideCancelButton: true,
        hideSubmitButton: true,
        submit: (data, e) => {
          e.hide();
          this.load();
          // eslint-disable-next-line no-undef
          wx.miniProgram.redirectTo("/pages/index/index");
        },
      },
    };
  },
  computed: {
    print() {
      return this.$route.query.action === "print";
    },
  },
  watch: {
    value(v) {
      if (this.normalizer.multiple) {
        this.model = v.map(Number);
        return;
      }
      this.model = v;
    },
  },
  methods: {
    handleInput(v) {
      if (v === 2 && this.normalizer.__config__.Process_agent_applyType) {
        const found = this.options.find((element) => element.dictValue === v);
        this.$emit("update", {
          [this.normalizer.__vModel__]: v,
          [this.normalizer.__vModel__ + "Name"]: found.dictLabel,
        });
        this.$refs.liModal.show({
          title: "代理列表",
          view: "workflow/modules/brokerListAdd.vue",
          ...this.modalConfig,
          submit: (data, e) => {
            this.$emit("broker", { data, disabled: true });
            e.hide();
          },
        });
        return;
      }
      if (this.normalizer.multiple) {
        const form = {
          dictLabel: [],
          dictValue: [],
        };
        v.forEach((item) => {
          const found = this.options.find(
            (element) => element.dictValue === item
          );
          form.dictLabel.push(found.dictLabel);
          form.dictValue.push(found.dictValue);
        });
        this.$emit("update", {
          [this.normalizer.__vModel__]: form.dictValue,
          [this.normalizer.__vModel__ + "Name"]: form.dictLabel,
        });
        return;
      }
      const found = this.options.find((element) => element.dictValue === v);
      this.$emit("update", {
        [this.normalizer.__vModel__]: v,
        [this.normalizer.__vModel__ + "Name"]: found.dictLabel,
      });
    },
  },
  mounted() {
    this.model = this.value;
    if (this.print && this.normalizer.printHide) {
      this.model = undefined;
    }
    this.options = this.normalizer.__slot__.options;
    if (this.normalizer.__config__.dataUrl) {
      getData(this.normalizer.__config__.dataUrl).then((res) => {
        this.options = res.data;
      });
    }
  },
};
</script>

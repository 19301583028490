<template>
  <el-select
    tl-dict-select
    v-model="selectedValue"
    :placeholder="placeholder"
    @change="handleChange"
    clearable
    :disabled="disabled"
  >
    <el-option
      v-for="dict in oprions"
      :key="dict.dictValue"
      :label="dict.dictLabel"
      :value="dict.dictValue"
    />
  </el-select>
</template>

<script>
import { dictOptions } from "@/request/system";

export default {
  name: "TlDictSelect",
  props: {
    dict: {
      type: String,
      default: undefined,
    },
    value: {
      type: [String, Number],
      default: undefined,
    },
    placeholder: {
      type: String,
      default: "请选择",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      oprions: [],
      selectedValue: undefined,
    };
  },
  watch: {
    value(v) {
      this.selectedValue = v;
    },
    selectedValue(v) {
      this.$emit("input", v);
    },
  },
  methods: {
    dictOptions() {
      dictOptions(this.dict).then((res) => {
        this.oprions = res.data;
      });
    },
    handleChange(value) {
      const found = this.oprions.find((element) => element.dictValue === value);
      setTimeout(() => {
        this.$emit("change", value, found);
      }, 200);
    },
  },
  mounted() {
    this.dictOptions();
    this.selectedValue = this.value;
  },
};
</script>

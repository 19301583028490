<template>
  <el-scrollbar class="comp-tagView">
    <div class="box-main">
      <router-link
        class="view-item"
        :to="item.path"
        v-for="item in tagView.activated"
        :key="item.name"
      >
        {{ item.meta.title }}

        <i
          v-if="tagView.activated.length > 1 && item.path !== '/home'"
          @click.prevent.stop="delView(item)"
          class="icon-del el-icon-close"
        ></i
      ></router-link>
    </div>
  </el-scrollbar>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "TagView",
  computed: {
    ...mapState({
      tagView: (state) => state.app.tagView,
      loginInfo: (state) => state.auth.loginInfo,
    }),
  },
  watch: {
    $route(value) {
      if (!value.meta.hiddenTagView) {
        this.$store.dispatch("app/addActivated", value);
      }
    },
    loginInfo(v) {
      if (!v) {
        this.$store.dispatch("app/cleanActivated");
      }
    },
  },
  methods: {
    delView(view) {
      this.$store.dispatch("app/delActivated", view);
      if (view.name === this.$route.name) {
        let activated = this.tagView.activated;
        this.$router.push(activated[activated.length - 1].path);
      }
    },
  },
  mounted() {
    if (!this.$route.meta.hiddenTagView) {
      this.$store.dispatch("app/addActivated", this.$route);
    }
  },
};
</script>

<style scoped lang="less">
.comp-tagView {
  white-space: nowrap;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 5px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12),
    0 0 3px 0 rgba(0, 0, 0, 0.04);
  .box-main {
    height: 40px;
    .view-item {
      color: #333333;
      text-decoration: none;
      display: inline-block;
      font-size: 14px;
      height: 40px;
      line-height: 40px;
      padding: 0 30px;
      background-image: linear-gradient(#ffffff, #eeeeee);
      border-radius: 10px;
      position: relative;

      &.router-link-active {
        background-image: linear-gradient(#ffffff, #ddf2e9);

        &::before {
          height: 24px;
          width: 5px;
          left: 8px;
          top: 8px;
          position: absolute;
          background-color: #06a06c;
          content: "";
          border-radius: 10px;
        }
      }
      &:hover {
        background-image: linear-gradient(#ffffff, #ddf2e9);
      }

      .icon-del {
        font-size: 14px;
        position: absolute;
        top: 13px;
        right: 8px;

        &:hover {
          background-color: #ff4500;
          color: #ffffff;
          border-radius: 50%;
        }
      }
    }
  }
}
</style>

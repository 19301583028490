<template>
  <el-form-item
    class="module-selectSeal"
    :label="normalizer.__config__.label"
    :prop="normalizer.__vModel__"
    v-if="!normalizer.__config__.hidden"
  >
    <el-row class="box-item" v-for="(item, index) in model" :key="index">
      <el-col :span="12">
        <el-select
          v-model="model[index].sealType"
          :clearable="normalizer.clearable"
          :disabled="normalizer.disabled"
          :filterable="normalizer.filterable"
          :placeholder="normalizer.placeholder"
          :style="normalizer.style"
          @change="handleInput"
        >
          <el-option
            v-for="item in options"
            :key="item.dictValue"
            :label="item.dictLabel"
            :value="item.dictValue"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="12">
        <el-input
          placeholder="请输入数量"
          v-model="model[index].sealNum"
          @input="handleInput"
          :readonly="normalizer.disabled"
        >
          <template slot="append">个</template>
        </el-input>
      </el-col>
    </el-row>
    <el-row v-if="!normalizer.disabled">
      <el-col :span="12">
        <el-button
          type="primary"
          icon="el-icon-plus"
          circle
          @click="handleAdd"
        />
        <el-button
          type="danger"
          icon="el-icon-delete"
          circle
          @click="handleDelete"
        />
      </el-col>
    </el-row>
  </el-form-item>
</template>

<script>
export default {
  name: "SelectSeal",
  props: {
    normalizer: {
      typeof: Object,
      default() {
        return {};
      },
    },
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      model: [],
    };
  },
  watch: {
    value(v) {
      this.model = v;
    },
  },
  methods: {
    handleAdd() {
      this.model.push({
        sealName: undefined,
        sealType: undefined,
        sealNum: undefined,
      });
      this.handleInput();
    },
    handleDelete() {
      this.model.splice(this.model.length - 1, 1);
      this.handleInput();
    },
    handleInput() {
      setTimeout(() => {
        this.model.forEach((element) => {
          const found = this.options.find(
            (option) => option.dictValue === element.sealType
          );
          element.sealName = found && found.dictLabel;
        });
        this.$emit("update", {
          [this.normalizer.__vModel__]: this.model,
        });
      }, 200);
    },
  },
  mounted() {
    this.model = this.value;
    this.options = this.normalizer.__slot__.options;
  },
};
</script>

<style lang="less" scoped>
.module-selectSeal {
  .box-item {
    border-bottom: 1px solid #333333 !important;

    &:last-child {
      border-bottom: none !important;
    }
  }
}
</style>

<template>
  <treeselect
    ref="treeselect"
    class="tl-tree-select"
    v-model="selectValue"
    :placeholder="placeholder"
    :multiple="multiple"
    :options="deptData"
    :size="size"
    :normalizer="normalizer"
    :disabled="disabled"
    :valueConsistsOf="valueConsistsOf"
    @input="handleChange"
    :flat="flat"
  />
</template>

<script>
import { treeDept } from "@/request/system";
import Treeselect from "@riophae/vue-treeselect";

export default {
  name: "TlDeptSelect",
  components: { Treeselect },
  props: {
    value: {
      type: [Number, String, Array],
      default: undefined,
    },
    placeholder: {
      type: String,
      default: "请选择部门",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    valueConsistsOf: {
      type: String,
      default: undefined,
    },
    flat: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      deptData: [],
      selectValue: undefined,
    };
  },
  watch: {
    value(v) {
      this.selectValue = v;
    },
    selectValue(v) {
      this.$emit("input", v);
    },
    disabled(v) {
      if (Array.isArray(this.deptData) && this.deptData[0]) {
        this.deptData[0].isDisabled = v;
      }
    },
  },
  methods: {
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.label,
        children: node.children,
      };
    },
    queryDeptTreeData() {
      treeDept().then((res) => {
        if (Array.isArray(res.data) && res.data[0]) {
          res.data[0].isDisabled = this.disabled;
        }
        this.deptData = res.data;
      });
    },
    handleChange(value) {
      setTimeout(() => {
        this.$emit(
          "change",
          value,
          value ? this.$refs.treeselect.getNode(value) : undefined
        );
      }, 200);
    },
  },
  mounted() {
    this.queryDeptTreeData();
    this.selectValue = this.value;
  },
};
</script>
<style lang="less" scoped>
.tl-tree-select {
  :deep(.vue-treeselect__control) {
    border-radius: 0;
  }
  &[size="small"] {
    :deep(.vue-treeselect__control) {
      height: 32px;
    }
  }
}
</style>

<template>
  <div>
    <el-form-item
      :label="normalizer.__config__.label"
      :prop="normalizer.__vModel__"
      v-if="!normalizer.__config__.hidden"
    >
      <el-input
        v-model="model"
        :autosize="!normalizer.autosize"
        :clearable="normalizer.clearable"
        :maxlength="normalizer.maxlength"
        :placeholder="normalizer.placeholder"
        :prefix-icon="normalizer['prefix-icon']"
        :suffix-icon="normalizer['suffix-icon']"
        :readonly="normalizer.disabled"
        :show-word-limit="normalizer['show-word-limit']"
        :style="normalizer.style"
        :type="normalizer.type"
        @input="handleInput"
        @focus="handleFocus"
        @change="handleChange"
        resize="none"
      >
        <template
          v-if="normalizer && normalizer.__slot__ && normalizer.__slot__.append"
          slot="append"
          >{{ normalizer.__slot__.append }}</template
        >
      </el-input>
    </el-form-item>
    <li-modal ref="liModal" />
  </div>
</template>

<script>
export default {
  name: "InputModule",
  props: {
    normalizer: {
      typeof: Object,
      default() {
        return {};
      },
    },
    value: {
      type: [String, Number, Array, Date],
      default: undefined,
    },
  },
  data() {
    return {
      model: undefined,
      modalConfig: {
        width: "1024px",
        center: true,
        hideCancelButton: true,
        hideSubmitButton: true,
        submit: (data, e) => {
          e.hide();
          this.load();
          // eslint-disable-next-line no-undef
          wx.miniProgram.redirectTo("/pages/index/index");
        },
      },
    };
  },
  computed: {
    print() {
      return this.$route.query.action === "print";
    },
  },
  watch: {
    value(v) {
      this.model = v;
    },
  },
  methods: {
    handleInput(v) {
      this.$emit("update", { [this.normalizer.__vModel__]: v });
    },
    handleChange(v) {
      // 合同金额特殊处理
      if (this.normalizer.__vModel__ === "lowercaseAmount") {
        if (v.indexOf(".") < 0) {
          v += ".00";
        } else if (v.split(".")[1].length === 1) {
          v += "0";
        }
        this.$emit("update", { [this.normalizer.__vModel__]: v });
      }
    },
    handleFocus() {
      if (this.normalizer.__config__.dialog && !this.normalizer.disabled) {
        this.$refs.liModal.show({
          title: "请选择用户",
          view: "workflow/modules/proposerAdd.vue",
          ...this.modalConfig,
          submit: (data, e) => {
            this.model = data.nickName;
            this.$emit("update", {
              [this.normalizer.__config__.dialog.field1]: data.nickName,
            });
            this.$emit("update", {
              [this.normalizer.__config__.dialog.field2]: data.userName,
            });
            e.hide();
          },
        });
      }
    },
  },
  mounted() {
    this.model = this.value;
    if (this.print && this.normalizer.printHide) {
      this.model = undefined;
    }
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-textarea__inner::-webkit-scrollbar {
  display: none;
}
/deep/ .el-textarea__inner {
  color: #333333 !important;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
}
/deep/ .el-input-group {
  line-height: unset;
}
</style>

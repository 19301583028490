/**
 * API for Archives
 */
import request from "./index";

/**
 * 员工档案列表
 * @param {int} fromData.pageNum
 * @param {int} fromData.pageSize
 */
export function listStaffFile(fromData) {
  return request({
    url: "zjwyArchive/staffFile/list",
    method: "POST",
    data: fromData,
  });
}

/**
 * 员工档案新增
 */
export function addStaffFile(fromData) {
  return request({
    url: "zjwyArchive/staffFile",
    method: "POST",
    data: fromData,
  });
}

/**
 * 员工档案修改
 */
export function updateStaffFile(fromData) {
  return request({
    url: "zjwyArchive/staffFile",
    method: "PUT",
    data: fromData,
  });
}

/**
 * 员工档案删除
 * @params {string} ids - 员工IDs
 */
export function deleteStaffFile(ids) {
  return request({
    url: "zjwyArchive/staffFile/" + ids,
    method: "DELETE",
  });
}

/**
 * 员工档案详情
 * @params {string} id - 员工ID
 */
export function detailsStaffFile(id) {
  return request({
    url: "zjwyArchive/staffFile/" + id,
    method: "GET",
  });
}

/**
 * 员工档案导出
 */
export function exportStaffFile() {
  return request({
    url: "zjwyArchive/staffFile/export",
    method: "POST",
    data: {},
    responseType: "blob",
  });
}

/**
 * 员工档案导入
 * @param {file} file - 文件对象
 */
export function importStaffFile(file) {
  const form = new FormData();
  form.append("file", file);
  return request({
    url: "zjwyArchive/staffFile/importExcel",
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: form,
  });
}

/**
 * 员工黑名单列表
 * @param {int} fromData.pageNum
 * @param {int} fromData.pageSize
 */
export function listStaffBlacklist(fromData) {
  return request({
    url: "zjwyArchive/staffBlacklist/list",
    method: "POST",
    data: fromData,
  });
}

/**
 * 员工黑名单新增
 * @param {int} fromData.pageNum
 * @param {int} fromData.pageSize
 */
export function addStaffBlacklist(fromData) {
  return request({
    url: "zjwyArchive/staffBlacklist",
    method: "POST",
    data: fromData,
  });
}

/**
 * 员工黑名单编辑
 */
export function updateStaffBlacklist(fromData) {
  return request({
    url: "zjwyArchive/staffBlacklist",
    method: "PUT",
    data: fromData,
  });
}

/**
 * 员工黑名单删除
 * @params {string} ids - ID
 */
export function deleteStaffBlacklist(ids) {
  return request({
    url: "zjwyArchive/staffBlacklist/" + ids,
    method: "DELETE",
  });
}

/**
 * 员工黑名单详情
 * @params {string} id - ID
 */
export function detailsStaffBlacklist(id) {
  return request({
    url: "zjwyArchive/staffBlacklist/" + id,
    method: "GET",
  });
}

/**
 * 员工黑名单导出
 */
export function exportStaffBlacklist() {
  return request({
    url: "zjwyArchive/staffBlacklist/export",
    method: "POST",
    data: {},
    responseType: "blob",
  });
}

/**
 * 流程代理单列表
 * @param {int} fromData.pageNum
 * @param {int} fromData.pageSize
 */
export function listProcessBroker(fromData) {
  return request({
    url: "boss/processBroker/list",
    method: "POST",
    data: fromData,
  });
}

/**
 * 流程代理列表（取消申请）
 */
export function listMyAgency() {
  return request({
    url: "zjwyArchive/zjwyBusiness/listMyAgency",
    method: "get",
  });
}
/**
 * 流程代理新增
 * @param {string} fromData.id - ID
 * @param {string} fromData.startTime - 开始时间
 * @param {string} fromData.endTime - 结束时间
 * @param {string} fromData.flow - 流程id
 * @param {string} fromData.agent - 代理人id
 * @param {string} fromData.flowName - 流程名
 * @param {string} fromData.agentName - 代理人名
 */
export function addProcessBroker(fromData) {
  return request({
    url: "boss/processBroker",
    method: "POST",
    data: fromData,
  });
}

/**
 * 流程代理编辑
 * @param {string} fromData.id - ID
 * @param {string} fromData.startTime - 开始时间
 * @param {string} fromData.endTime - 结束时间
 * @param {string} fromData.flow - 流程id
 * @param {string} fromData.agent - 代理人id
 * @param {string} fromData.flowName - 流程名
 * @param {string} fromData.agentName - 代理人名
 */
export function updateProcessBroker(fromData) {
  return request({
    url: "boss/processBroker",
    method: "PUT",
    data: fromData,
  });
}

/**
 * 流程代理删除
 * @params {string} ids - IDs
 */
export function deleteProcessBroker(ids) {
  return request({
    url: "boss/processBroker/" + ids,
    method: "DELETE",
  });
}

/**
 * 流程代理详情
 * @params {string} id - ID
 */
export function detailsProcessBroker(id) {
  return request({
    url: "boss/processBroker/" + id,
    method: "GET",
  });
}

/**
 * 员排班规则列表
 * @param {int} fromData.pageNum
 * @param {int} fromData.pageSize
 */
export function listSchedulingRules(fromData) {
  return request({
    url: "zjwyArchive/schedulingRules/list",
    method: "POST",
    data: fromData,
  });
}

/**
 * 排班规则新增
 * @param {string} fromData.name - 班次名称
 * @param {string} fromData.referenceDeptCode - 部门ID
 * @param {string} fromData.referencePostCode - 岗位ID
 * @param {string} fromData.dept - 部门名称
 * @param {string} fromData.post - 岗位名称
 * @param {string} fromData.restType - 休假类型 字典zjwy_rest_type
 * @param {string} fromData.restDay - 休假日/天数
 * @param {string} fromData.isCheck - 是否发起校验
 */
export function addSchedulingRules(fromData) {
  return request({
    url: "zjwyArchive/schedulingRules",
    method: "POST",
    data: fromData,
  });
}

/**
 * 排班规则编辑
 * @param {string} fromData.id
 * @param {string} fromData.name - 班次名称
 * @param {string} fromData.referenceDeptCode - 部门ID
 * @param {string} fromData.referencePostCode - 岗位ID
 * @param {string} fromData.dept - 部门名称
 * @param {string} fromData.post - 岗位名称
 * @param {string} fromData.restType - 休假类型 字典zjwy_rest_type
 * @param {string} fromData.restDay - 休假日/天数
 * @param {string} fromData.isCheck - 是否发起校验
 *
 */
export function updateSchedulingRules(fromData) {
  return request({
    url: "zjwyArchive/schedulingRules",
    method: "PUT",
    data: fromData,
  });
}

/**
 * 排班规则删除
 * @params {string} ids - IDs
 */
export function deleteSchedulingRules(ids) {
  return request({
    url: "zjwyArchive/schedulingRules/" + ids,
    method: "DELETE",
  });
}

/**
 * 排班规则详情
 * @params {string} id - ID
 */
export function detailsSchedulingRules(id) {
  return request({
    url: "zjwyArchive/schedulingRules/" + id,
    method: "GET",
  });
}

/**
 * 排班规则导出
 */
export function exportSchedulingRules() {
  return request({
    url: "zjwyArchive/schedulingRules/export",
    method: "POST",
    data: {},
    responseType: "blob",
  });
}

/**
 * 更新上月数据
 */
export function updatePreSchedule(formData) {
  return request({
    url: "zjwyArchive/schedulingRules/updatePreSchedule",
    method: "POST",
    data: formData,
  });
}

/**
 * 员工档案未绑定列表
 * @param {int} fromData.pageNum
 * @param {int} fromData.pageSize
 */
export function listWithoutRelate(data) {
  return request({
    url: "zjwyArchive/staffFile/listWithoutRelate",
    method: "POST",
    data: data,
  });
}

/**
 * 员工档案新增绑定
 * @param {int} fromData.userName - 用户名
 * @param {int} fromData.staffCode - code
 */
export function addRelateStaff(data) {
  return request({
    url: "zjwyArchive/staffFile/relateStaff",
    method: "POST",
    data: data,
  });
}

/**
 * 年假人员信息列表
 * @param {int} fromData.pageNum
 * @param {int} fromData.pageSize
 */
export function listAnnualLeave(fromData) {
  return request({
    url: "zjwyArchive/annualLeaveStaff/list",
    method: "POST",
    data: fromData,
  });
}

/**
 * 年假更新全部
 *
 * @param {int} fromData.type - 类型
 */
export function loadAnnualLeave(fromData) {
  return request({
    url: "zjwyArchive/annualLeaveStaff/updateAll",
    method: "POST",
    data: fromData,
  });
}

/**
 * 年假已休清零
 *
 * @param {int} fromData.ids - 人员ID
 */
export function cleanUsedAnnualLeave(fromData) {
  return request({
    url: "zjwyArchive/annualLeaveStaff/cleanUsed",
    method: "POST",
    data: fromData,
  });
}

/**
 * 年假更新
 *
 * @param {int} fromData.id - ID
 * @param {int} fromData.status - 状态固定3
 * @param {int} fromData.restDayTotal - 休假总天数
 */
export function updateAnnualLeave(fromData) {
  return request({
    url: "zjwyArchive/annualLeaveStaff",
    method: "PUT",
    data: fromData,
  });
}

/**
 * 年假导出
 */
export function exportAnnualLeave() {
  return request({
    url: "zjwyArchive/annualLeaveStaff/export",
    method: "POST",
    data: {},
    responseType: "blob",
  });
}

/**
 * 节假日列表
 * @param {int} fromData.pageNum
 * @param {int} fromData.pageSize
 */
export function listHoliday(fromData) {
  return request({
    url: "zjwyArchive/holiday/list",
    method: "POST",
    data: fromData,
  });
}

/**
 * 节假日新增
 * @param {string} fromData.name - 名称
 * @param {string} fromData.date - 日期
 */
export function addHoliday(fromData) {
  return request({
    url: "zjwyArchive/holiday",
    method: "POST",
    data: fromData,
  });
}

/**
 * 节假日编辑
 * @param {string} fromData.name - 名称
 * @param {string} fromData.date - 日期
 */
export function updateHoliday(fromData) {
  return request({
    url: "zjwyArchive/holiday",
    method: "PUT",
    data: fromData,
  });
}

/**
 * 节假日删除
 * @params {string} ids - IDs
 */
export function deleteHoliday(ids) {
  return request({
    url: "zjwyArchive/holiday/" + ids,
    method: "DELETE",
  });
}

/**
 * 节假日详情
 * @params {string} id - ID
 */
export function detailsHoliday(id) {
  return request({
    url: "zjwyArchive/holiday/" + id,
    method: "GET",
  });
}

/**
 *  节假日导出
 */
export function exportHoliday() {
  return request({
    url: "zjwyArchive/holiday/export",
    method: "POST",
    data: {},
    responseType: "blob",
  });
}

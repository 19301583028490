<template>
  <div class="li-workflowForm">
    <h1 class="text-title">{{ config.name }}</h1>
    <el-row class="form-workflow">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        :label-width="normalizer.labelWidth + 'px'"
      >
        <el-col
          v-for="(item, index) in normalizer.fields"
          :span="item.__config__.span"
          :key="index"
        >
          <date-picker-module
            v-if="item.__config__.tag === 'el-date-picker'"
            :normalizer="item"
            :value="form[item.__vModel__]"
            @update="handleUpdate"
          />
          <select-module
            v-if="item.__config__.tag === 'el-select'"
            :normalizer="item"
            :value="form[item.__vModel__]"
            @update="handleUpdate"
            @broker="handleBroker"
          />
          <input-module
            v-if="item.__config__.tag === 'el-input'"
            :normalizer="item"
            :value="form[item.__vModel__]"
            @update="handleUpdate"
          />
          <upload-module
            v-if="item.__config__.tag === 'el-upload'"
            :normalizer="item"
            :value="form[item.__vModel__]"
            @update="handleUpdate"
          />
          <table-module
            v-if="item.__config__.tag === 'table'"
            :normalizer="item"
            :value="form[item.__vModel__]"
            :purchaseCheck="hasPermission('purchaseCheck')"
            @update="handleUpdate"
            :key="key"
          />
          <dept-select
            v-if="item.__config__.tag === 'dept-select'"
            :normalizer="item"
            :value="form[item.__vModel__]"
            @update="handleUpdate"
          />
          <select-seal
            v-if="item.__config__.tag === 'seal'"
            :normalizer="item"
            :value="form[item.__vModel__]"
            @update="handleUpdate"
          />

          <checkbox-module
            v-if="item.__config__.tag === 'el-checkbox-group'"
            :normalizer="item"
            :value="form[item.__vModel__]"
            @update="handleUpdate"
          />
        </el-col>
      </el-form>
    </el-row>
  </div>
</template>

<script>
import DatePickerModule from "./modules/DatePickerModule.vue";
import SelectModule from "./modules/SelectModule.vue";
import InputModule from "./modules/InputModule.vue";
import UploadModule from "./modules/UploadModule.vue";
import TableModule from "./modules/TableModule.vue";
import DeptSelect from "./modules/DeptSelect.vue";
import SelectSeal from "./modules/SelectSeal.vue";
import CheckboxModule from "./modules/CheckboxModule.vue";
import { convertCurrency } from "@/utils/utils";
import { mapState } from "vuex";

export default {
  name: "LiWorkflowForm",
  components: {
    DatePickerModule,
    SelectModule,
    InputModule,
    UploadModule,
    TableModule,
    DeptSelect,
    SelectSeal,
    CheckboxModule,
  },
  props: {
    origin: {
      typeof: Object,
      default() {
        return {};
      },
    },
    config: {
      typeof: Object,
      default() {
        return {};
      },
    },
    disabled: {
      typeof: Number,
    },
    value: {
      typeof: Array,
      default() {
        return {};
      },
    },
    action: {
      typeof: String,
      default: undefined,
    },
  },
  computed: {
    ...mapState({
      loginInfo: (state) => state.auth.loginInfo,
    }),
  },
  data() {
    return {
      normalizer: {},
      form: {},
      rules: {},
      superTube: undefined,
      key: 1,
    };
  },
  watch: {
    value(v) {
      this.form = { ...v };
    },
  },
  methods: {
    handleBroker(event) {
      // 用来刷新组件TableModule
      this.key = this.key + 1;
      if (event.disabled) {
        this.normalizer.fields.filter((item) => {
          item.disabled = true;
        });
      }
      this.form = { ...this.form, ...event.data };
      this.$emit("input", this.form);
      setTimeout(() => {
        this.$refs["form"].clearValidate(Object.keys(event.data)[0]);
      }, 10);
    },
    hasPermission(v) {
      return (
        this.origin &&
        this.origin.button &&
        this.origin.button.find((element) => element.action === v)
      );
    },
    init() {
      let formJson = JSON.parse(this.config.formJson);
      this.normalizer = formJson;
      const form = {};
      const rules = {};
      this.normalizer.fields.forEach((element) => {
        form[element.__vModel__] = undefined;
        if (element.__config__.required) {
          rules[element.__vModel__] = [
            { required: true, message: element.placeholder, trigger: "blur" },
          ];
        } else if (element.__config__.regList) {
          rules[element.__vModel__] = element.__config__.regList;
        }
        if (element.__config__.renderKey == 1661132374320) {
          rules["lowercaseAmount"] = [
            { pattern: "^(\\-|\\+)?\\d+(\\.\\d+)?$", message: "必须为数值" },
          ];
        }
        const find = this.config.permission.find(
          (item) => item.field === element.__vModel__
        );
        if (find && find.permission === "r") {
          element.disabled = true;
        }
      });
      if (this.superTube && !this.disabled) {
        let fields = this.normalizer.fields.map((item) => {
          item.disabled = false;
          return item;
        });
        this.normalizer.fields = fields;
      }
      this.form = { ...form, ...this.form };
      this.rules = rules;
    },

    handleUpdate(data) {
      /** 写死脚本 */
      if (Object.keys(data).find((element) => element === "lowercaseAmount")) {
        if (data["lowercaseAmount"]) {
          data["capitalizeAmount"] = convertCurrency(data["lowercaseAmount"]);
        } else {
          data["capitalizeAmount"] = undefined;
        }
      }

      this.form = { ...this.form, ...data };
      this.$emit("input", this.form);
      setTimeout(() => {
        this.$refs["form"].clearValidate(Object.keys(data)[0]);
      }, 10);
    },
    validate(functionValidate) {
      this.$refs["form"].validate((valid) => {
        functionValidate(valid);
      });
    },
  },
  mounted() {
    if (this.loginInfo && this.action === "edit") {
      this.superTube = this.loginInfo.permissions.some(
        (item) => item === "boss:workFlow:updateFormData"
      );
    }
    this.init();

    this.form = { ...this.value };
  },
};
</script>

<style lang="less" scoped>
.li-workflowForm {
  .text-title {
    text-align: center;
    padding: 15px 0;
    font-size: 28px;
  }

  .form-workflow {
    width: 100%;
    margin: 20px auto;
    border-top: 1px solid #333333;
    border-left: 1px solid #333333;
    :deep(.el-form) {
      .el-form-item {
        margin-bottom: 0;
        border-bottom: 1px solid #333333;
        border-right: 1px solid #333333;
        position: relative;
        .el-form-item__label {
          text-align: center;
          padding: 0;
          position: absolute;
          top: calc(50% - 20px);
        }

        .el-form-item__content {
          border-left: 1px solid #333333;
          line-height: 45px;
          font-size: 16px;
          * {
            border: none;
          }
          .el-checkbox__inner {
            border: 1px solid #dcdfe6;
            font-size: 16px;
          }
          .el-form-item__error {
            top: 65%;
            left: 15px;
          }
          .el-textarea {
            textarea {
              font-size: 16px;
            }
          }
          .el-input {
            font-size: 16px;
            &.is-disabled {
              .el-input__inner {
                background-color: unset;
                color: #333333;
              }
            }
          }
        }
      }
    }
  }
}
</style>
>

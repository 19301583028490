import LayoutAdmin from "./src/index.vue";
import store from "@/store";
import router from "@/router";

export default LayoutAdmin;
/**
 * 关闭当前页
 */
export function close() {
  store.dispatch("app/delActivated", router.currentRoute);
  router.back();
}

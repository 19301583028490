<template>
  <div class="tl-user-select">
    <el-input :placeholder="placeholder" :value="modelName" readonly>
      <i
        v-if="!disabled"
        slot="prefix"
        class="el-input__icon el-icon-user-solid"
        style="cursor: pointer"
        @click="visible = true"
      ></i>
    </el-input>
    <el-dialog title="请选择用户" :visible.sync="visible" width="1024px">
      <el-row>
        <el-col :span="4">
          <el-tree
            :data="deptOptions"
            :props="defaultProps"
            :expand-on-click-node="false"
            :filter-node-method="filterNode"
            ref="tree"
            default-expand-all
            @node-click="handleNodeClick"
          />
        </el-col>
        <el-col :span="20">
          <el-form
            :model="condition"
            ref="queryForm"
            size="small"
            :inline="true"
            label-width="68px"
          >
            <el-form-item label="用户名称" prop="userName">
              <el-input
                v-model="condition.userName"
                placeholder="请输入用户名称"
                clearable
                style="width: 240px"
              />
            </el-form-item>
            <el-form-item label="昵称" prop="nickName">
              <el-input
                v-model="condition.nickName"
                placeholder="请输入昵称"
                clearable
                style="width: 240px"
              />
            </el-form-item>
            <el-form-item>
              <el-button
                type="search"
                icon="el-icon-search"
                size="mini"
                @click="handleQuery"
                >搜索</el-button
              >
              <el-button
                type="reset"
                icon="el-icon-refresh"
                size="mini"
                @click="handleResetQuery"
                >重置</el-button
              >
            </el-form-item>
          </el-form>
          <el-table
            :data="list"
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              label="用户编号"
              align="center"
              key="userId"
              prop="userId"
            />
            <el-table-column
              label="用户名称"
              align="center"
              key="userName"
              prop="userName"
            />
            <el-table-column
              label="用户昵称"
              align="center"
              key="nickName"
              prop="nickName"
            />
            <el-table-column
              label="部门"
              align="center"
              key="deptName"
              prop="dept.deptName"
            />
            <el-table-column label="操作" align="center" width="55px">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="text-edit"
                  @click="handleSelected(scope.row)"
                  >选择</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            pagination
            background
            :page-sizes="[10, 20, 50, 100]"
            :current-page="condition.pageNum"
            :page-size="condition.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          />
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { treeDept, listUser } from "@/request/system";

export default {
  name: "TlUserSelect",
  props: {
    value: {
      type: [String, Number],
      default: undefined,
    },
    label: {
      type: String,
      default: undefined,
    },
    placeholder: {
      type: String,
      default: "请选择",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      condition: {
        pageNum: 1,
        pageSize: 10,
        userName: undefined,
        phonenumber: undefined,
        deptId: undefined,
      },
      list: [],
      total: 0,
      model: undefined,
      modelName: undefined,
      visible: false,
      deptOptions: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
    };
  },
  watch: {
    value(v) {
      this.model = v;
    },
    label(v) {
      this.modelName = v;
    },
    model(v) {
      this.$emit("input", v);
    },
  },
  methods: {
    query(pageNum, pageSize) {
      this.condition.pageNum = pageNum;
      this.condition.pageSize = pageSize;
      this.loading = true;
      listUser(this.condition).then((res) => {
        this.loading = false;
        this.list = res.rows;
        this.total = res.total;
      });
    },
    // 筛选节点
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    // 节点单击事件
    handleNodeClick(data) {
      this.condition.deptId = data.id;
      this.handleQuery();
    },
    handleQuery() {
      this.query(1, this.condition.pageSize);
    },
    handleSelectionChange(selection) {
      this.checkedIds = selection.map((item) => item.userId);
    },
    handleResetQuery() {
      this.condition = {
        pageNum: 1,
        pageSize: 10,
        userName: undefined,
        phonenumber: undefined,
        deptId: undefined,
      };
      this.query(1, this.condition.pageSize);
    },
    handleCurrentChange(pageNum) {
      this.query(pageNum, this.condition.pageSize);
    },
    handleSizeChange(pageSize) {
      this.query(1, pageSize);
    },
    handleSelected(row) {
      this.model = row.userName;
      this.modelName = row.nickName;
      this.$emit("on-selected", row.nickName, row);
      this.visible = false;
    },
  },
  mounted() {
    this.model = this.value;
    this.modelName = this.label;
    treeDept().then((res) => {
      this.deptOptions = res.data;
    });
    this.query();
  },
};
</script>

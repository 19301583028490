/**
 * API for Archives
 */
import request from "./index";

export function dataConsole() {
  return request({
    url: "zjwyArchive/zjwyBusiness/portal",
    method: "GET",
  });
}

/**
 * 任务流程列表
 */
export function listProBusiness() {
  return request({
    url: "zjwyArchive/zjwyBusiness/proBusinessList",
    method: "POST",
  });
}

/**
 * 查询表单
 * @param businessId - 业务ID
 */
export function detailsForm(businessId) {
  return request({
    url: "boss/tygxWorkFlow/initForm/" + businessId,
    method: "GET",
  });
}

/**
 * 流程启动
 * @param bpmBusinessId 流程业务配置 ID
 * @param jsonData 表单 Json 数据
 * @param workFlowInsId 流程实例ID
 */
export function startProcess(bpmBusinessId, jsonData, workFlowInsId) {
  return request({
    url: "boss/tygxWorkFlow/startProcess",
    method: "POST",
    data: {
      bpmBusinessId,
      jsonData,
      workFlowInsId,
    },
  });
}

/**
 * 流程列表过滤条件
 * @param {string} formVersionId - 表单版本ID
 */
export function listInstanceFromFilter(formVersionId) {
  return request({
    url: "boss/form/TygxFormVersionDetail/listFilter/" + formVersionId,
    method: "GET",
  });
}

/**
 * 流程列表表格头部
 * @param {string} formVersionId - 表单版本ID
 */
export function listInstanceFromTitle(formVersionId) {
  return request({
    url: "boss/form/TygxFormVersionDetail/listShowInGrid/" + formVersionId,
    method: "GET",
  });
}

/**
 * 流程列表表格数据
 * @param {string} condition.versionId - 表单版本ID
 * @param {string} condition.pageNum - 页码
 * @param {string} condition.pageSize - 页长
 */
export function listInstanceFromData(condition) {
  return request({
    url: "boss/form/tygxFormData/comprehensiveSearchForZj",
    method: "POST",
    data: condition,
  });
}

/**
 * 任务列表
 */
export function listTodo(condition) {
  return request({
    url: "boss/tygxWorkFlow/taskList",
    method: "POST",
    data: condition,
  });
}

/**
 * 任务明细
 * @param {string} condition.taskId - 任务ID
 * @param {string} condition.proInsId - 流程实例ID
 * @param {string} condition.processDefinitionId - 流程ID
 */
export function detailsTodo(condition) {
  return request({
    url: "zjwyArchive/zjwyBusiness/taskDetail",
    method: "POST",
    data: condition,
  });
}

/**
<<<<<<< HEAD
 * 排版打印明细
=======
 * 打扰明细
>>>>>>> 8e836317929229d10f32505348bccb37208213cd
 * @param {string} condition.taskId - 任务ID
 * @param {string} condition.proInsId - 流程实例ID
 * @param {string} condition.processDefinitionId - 流程ID
 */
export function printTodo(condition) {
  return request({
    url: "zjwyArchive/zjwyBusiness/attendPrintContent",
    method: "POST",
    data: condition,
  });
}

/**
 * 任务审批
 * @param {string} formData.taskId - 任务id
 * @param {string} formData.bpmBusinessId - 业务流程id
 * @param {string} formData.json - 按钮数据
 * @param {string} formData.json.action - 审核操作
 * @param {string} formData.json.approveDesc - 审核意见
 * @param {string} formData.nextHandler - 候选人
 * @param {string} formData.formData - 表单数据
 */
export function handleTodo(formData) {
  return request({
    url: "boss/tygxWorkFlow/taskHandle",
    method: "POST",
    data: formData,
  });
}

/**
 * 参与事项
 */
export function listPartIn(condition) {
  return request({
    url: "boss/tygxWorkFlow/myPartakeTaskList",
    method: "POST",
    data: condition,
  });
}

/**
 * 我的事项
 */
export function listMyItem(condition) {
  return request({
    url: "boss/tygxWorkFlow/myApplyTaskList",
    method: "POST",
    data: condition,
  });
}

/**
 * 删除我的事项
 * @param {string} workFlowIds - workFlowIdS
 */
export function deleteMyItem(workFlowId) {
  return request({
    url: "boss/TygxWorkFlow/TygxWorkFlowInsInfo/" + workFlowId,
    method: "DELETE",
  });
}

/**
 * 流程草稿保存
 * @param bpmBusinessId 流程业务配置 ID
 * @param jsonData 表单 Json 数据
 * @param workFlowInsId 流程实例ID
 */
export function startDraft(bpmBusinessId, jsonData, workFlowInsId) {
  return request({
    url: "boss/tygxWorkFlow/createDraft",
    method: "POST",
    data: {
      bpmBusinessId,
      jsonData,
      workFlowInsId,
    },
  });
}

/**
 * 流程草稿详情
 * @param workFlowInsId 流程实例ID
 */
export function detailsDraft(workFlowInsId) {
  return request({
    url: "zjwyArchive/zjwyBusiness/draftDetail/" + workFlowInsId,
    method: "GET",
  });
}

/**
 * 合同审批-确认盖章
 * @param {string} formData.officialSeal 公章数量
 * @param {string} formData.chapterContract 合同章数量
 * @param {string} formData.proInsId activiti流程实例ID
 */
export function confirmSeal(formData) {
  return request({
    url: "zjwyArchive/zjwyBusiness/personnelSeal",
    method: "POST",
    data: formData,
  });
}

/**
 * 统计查询-合同统计
 * @param {object} formData - 参数
 */
export function listContract(formData) {
  return request({
    url: "zjwyArchive/zjwyBusiness/contractList",
    method: "POST",
    data: formData,
  });
}

/**
 * 统计查询-采购统计
 * @param {object} formData - 参数
 */
export function listPurchase(formData) {
  return request({
    url: "zjwyArchive/zjwyBusiness/purchaseList",
    method: "POST",
    data: formData,
  });
}

/**
 * 统计查询-采购统计一级
 * @param {object} formData - 参数
 */
export function listSum(formData) {
  return request({
    url: "zjwyArchive/purchase/listSum",
    method: "POST",
    data: formData,
  });
}

/**
 * 统计查询-采购统计二级
 * @param {object} formData - 参数
 */
export function listType(formData) {
  return request({
    url: "zjwyArchive/purchase/listType",
    method: "POST",
    data: formData,
  });
}

/**
 * 统计查询-流程明细
 * @param {object} formData - 参数
 */
export function taskDetailPurchase(formData) {
  return request({
    url: "boss/tygxWorkFlow/taskDetaile",
    method: "POST",
    data: formData,
  });
}

/**
 * 采购计划导入
 * @param {file} file - 文件对象
 */
export function importPurchase(file) {
  const form = new FormData();
  form.append("file", file);
  return request({
    url: "zjwyArchive/zjwyBusiness/importPurchase",
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: form,
  });
}

/**
 * 紧急采购导入
 * @param {file} file - 文件对象
 */
export function UrgencyimportPurchase(file, businessId) {
  const form = new FormData();
  form.append("file", file);
  return request({
    url: "zjwyArchive/zjwyBusiness/importPurchase/" + businessId,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: form,
  });
}

/**
 * 更新表单数据
 * @param {object} formData - 参数
 */
export function updateFormData(formData) {
  return request({
    url: "boss/tygxWorkFlow/api/updateFormData",
    method: "POST",
    data: formData,
  });
}

/**
 * 加载流程图
 * @param {string} proInsId - 参数
 */
export function workFlowImage(proInsId) {
  return request({
    url: "boss/tygxWorkFlow/workFlowImage/" + proInsId,
    method: "get",
  });
}

/**
 * 查询车场进出记录列表
 * @param {object} formData - 参数
 */
export function zjwyCarParkAccessList(formData) {
  return request({
    url: "zjwyArchive/zjwyCarParkAccess/list",
    method: "POST",
    data: formData,
  });
}

/**
 * 导出车场进出记录列表
 * @param {object} formData - 参数
 */
export function zjwyCarParkAccessExport(formData) {
  return request({
    url: "zjwyArchive/zjwyCarParkAccess/export",
    method: "POST",
    data: formData,
    responseType: "blob",
  });
}

/**
 * 任务指派
 * @param {object} formData - 参数
 */
export function taskAssignmentAssign(formData) {
  return request({
    url: "boss/tygxWorkFlow/assign",
    method: "POST",
    data: formData,
  });
}

/**
 * 下载计划表导入模板
 */
export function exportPurchase(type) {
  return request({
    url: "zjwyArchive/zjwyBusiness/exportPurchase/" + type,
    method: "GET",
    responseType: "blob",
  });
}

/**
 * 流程任务审批历史
 * @param {string} proInsId - 流程实例ID
 */
export function listApproveTaskHistory(proInsId) {
  return request({
    url: "boss/tygxWorkFlow/historyList",
    method: "POST",
    data: {
      proInsId,
    },
  });
}

/**
 * 获取当前用户（档案）职务
 */
export function listAgencyPost(data) {
  return request({
    url: "zjwyArchive/zjwyPost/listAgencyPost",
    method: "post",
    data,
  });
}

/**
 * 校验排班
 */
export function verifySchedule(data) {
  return request({
    url: "zjwyArchive/schedulingRules/checkAttend",
    method: "post",
    data,
  });
}

/**
 * 重新发起排班
 */
export function startSchedule(formData) {
  return request({
    url: "zjwyArchive/schedulingRules/initiateScheduling",
    method: "post",
    data: formData,
  });
}

<template>
  <el-form-item
    :label="normalizer.__config__.label"
    :prop="normalizer.__vModel__"
    v-if="!normalizer.__config__.hidden"
  >
    <div class="module-upload">
      <div class="box-item clear" v-for="(item, index) in model" :key="index">
        <div
          class="link-item left"
          style="cursor: pointer"
          :href="item.url"
          target="_blank"
          @click="handleA(item)"
        >
          <img class="image-item" v-if="isIamge(item)" :src="item.url" />
          <p class="text-file el-icon-document" v-if="!isIamge(item)">
            {{ item.nameOrigin }}
          </p>
        </div>
        <el-button
          v-if="!normalizer.disabled"
          type="danger"
          class="btn-delete left"
          icon="el-icon-delete"
          circle
          size="mini"
          @click="handleDelete(index)"
        ></el-button>
      </div>
      <el-progress
        v-if="progress"
        :percentage="percentage"
        :status="status"
        :format="format"
      ></el-progress>
      <el-button
        v-if="!normalizer.disabled && !progress"
        @click="handleUpload"
        class="btn-upload"
        size="small"
        icon="el-icon-upload"
        >{{ normalizer.__config__.buttonText }}</el-button
      >
    </div>
  </el-form-item>
</template>

<script>
import { uploadFile, downloadFileNSpecifyFilename } from "@/request/system";
export default {
  name: "UploadModule",
  props: {
    normalizer: {
      typeof: Object,
      default() {
        return {};
      },
    },
    value: {
      type: [Array],
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      model: undefined,
      progress: false,
      percentage: 0,
      status: "success",
    };
  },
  computed: {
    print() {
      return this.$route.query.action === "print";
    },
  },
  watch: {
    value(v) {
      this.model = v;
    },
  },
  methods: {
    format(percentage) {
      return percentage === 100 ? "满" : `${percentage}%`;
    },
    handleUpload() {
      let _this = this;
      const input = document.createElement("input");
      input.accept = this.normalizer.accept;
      input.type = "file";
      input.multiple = "multiple";
      input.onchange = function () {
        _this.progress = true;
        let percentage = setInterval(function () {
          if (_this.percentage < 80) {
            _this.percentage += 10;
          }
        }, 1000);
        for (let index = 0; index < this.files.length; index++) {
          const item = this.files[index];
          uploadFile(item)
            .then((res) => {
              clearInterval(percentage);
              _this.progress = false;
              _this.percentage = 100;
              _this.model.push(res.data);
              _this.$emit("update", {
                [_this.normalizer.__vModel__]: _this.model,
              });
            })
            .catch(() => {
              clearInterval(percentage);
              _this.progress = false;
              _this.status = "exception";
            });
        }
      };
      input.click();
    },
    handleDelete(index) {
      this.model.splice(index, 1);
      this.$emit("update", { [this.normalizer.__vModel__]: this.model });
    },
    isIamge(item) {
      return (
        ["png", "jpg", "jpeg"].indexOf(
          item.name.split(".")[item.name.split(".").length - 1]
        ) > -1
      );
    },
    handleA(event) {
      let data = {
        filename: event.nameOrigin,
        url: event.url,
      };
      downloadFileNSpecifyFilename(data).then((res) => {
        this.$utils.downloadBlob(res, event.nameOrigin);
      });
    },
  },
  mounted() {
    this.model = this.value;
    if (this.print && this.normalizer.printHide) {
      this.model = undefined;
    }
  },
};
</script>
<style lang="less" scoped>
.module-upload {
  min-height: 45px;
  .btn-upload {
    color: #06a06c;
  }

  .box-item {
    .link-item {
      text-decoration: none;
      color: #333333;
      .image-item {
        width: 100px;
        display: inline-block;
        margin: 10px;
      }

      .text-file {
        font-size: 16px;
        margin: 10px;
      }
    }

    .btn-delete {
      margin-top: 10px;
    }
  }
}
</style>

import { listProBusiness } from "@/request/workflow";
/**
 * 获取工作流菜单
 * @returns 工作流菜单
 */
export function getWorkflowRoutes() {
  return new Promise((resolve, reject) => {
    listProBusiness()
      .then((res) => {
        const leaveApply = res.data.find(
          (element) => element.businessType === "attend"
        );
        const workflowRoutes = {
          path: "/workflow",
          name: "Workflow",
          component: "LayoutAdmin",
          meta: {
            icon: "icon-sideBarWorkflow",
            title: "行政审批",
            group: true,
          },
          children: [],
        };
        res.data.forEach((item) => {
          if (item.businessType === "approval") {
            workflowRoutes.children.push({
              name: "WorkflowList" + item.id,
              path: "/workflow/list/" + item.id,
              component: "workflow/list.vue",
              meta: {
                businessType: item.businessType,
                title: item.name,
                group: false,
                menuType: "m",
                businessId: item.id, // 业务ID
                formId: item.formId, // 业务表单ID
                formVersionId: item.formVersionId, // // 业务表单版本ID
                processKey: item.processKey, // 流程Key
              },
            });
          }
        });
        resolve({ workflowRoutes, leaveApply });
      })
      .catch((error) => {
        reject(error);
      });
  });
}

<template>
  <el-dialog
    class="li-modal"
    :custom-class="options.customClass"
    :title="options.title"
    :visible.sync="visible"
    :width="options.width"
    :fullscreen="options.fullscreen"
    :top="options.top"
    :modal="options.modal"
    :modal-append-to-body="options.modalAppendToBody"
    :append-to-body="options.appendToBody"
    :lock-scroll="options.lockScroll"
    :close-on-click-modal="options.closeOnClickModal"
    :close-on-press-escape="options.closeOnPressEscape"
    :show-close="options.showClose"
    :before-close="options.beforeClose"
    :center="options.center"
    :destroy-on-close="options.destroyOnClose"
  >
    <component
      ref="view"
      :is="component"
      @modal-cancel="handleCancel"
      @modal-submit="handleSubmit"
      :params="options.params"
    />
    <span slot="footer" class="dialog-footer">
      <el-button v-if="!options.hideCancelButton" @click="handleCancel">{{
        options.cancelButtonName
      }}</el-button>
      <el-button
        v-if="!options.hideSubmitButton"
        type="primary"
        @click="handleSubmit"
        >{{ options.submitButtonName }}</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "LiModal",
  data() {
    return {
      visible: false, // 是否显示
      component: undefined, // 加载
      /* 模态框默认配置项 */
      defaultOptions: {
        view: undefined, // des:view，只能是src/views目录下的vue文件 | type:String
        customClass: undefined, // des:自定义类 | type:String
        title: undefined, // des:标题 | type:String
        width: "50%", // des:宽度 | type:String
        fullscreen: false, // des:是否全屏 | type:Boolean
        top: "15vh", // des:Dialog CSS 中的 margin-top 值 type:String
        modal: true, // des:是否需要遮罩层 | type:Boolean
        modalAppendToBody: true, // des:遮罩层是否插入至 body 元素上，若为 false，则遮罩层会插入至 Dialog 的父元素上 | type:Boolean
        appendToBody: true, // des:Dialog 自身是否插入至 body 元素上。嵌套的 Dialog 必须指定该属性并赋值为 true | type:Boolean
        lockScroll: true, // des:是否在 Dialog 出现时将 body 滚动锁定 | type:Boolean
        closeOnClickModal: false, // des:是否可以通过点击 modal 关闭 Dialog | type:Boolean
        closeOnPressEscape: true, // des:是否可以通过按下 ESC 关闭 Dialog | type:Boolean
        showClose: true, // des:是否显示关闭按钮 | type:Boolean
        center: false, // des:是否对头部和底部采用居中布局 | type:Boolean
        destroyOnClose: true, // des:关闭时销毁 Dialog 中的元素 | type:Boolean
        cancelButtonName: "取 消", // 取消按钮名称
        submitButtonName: "确 定", // 确定按钮名称
        hideCancelButton: false, // 隐藏取消按钮
        hideSubmitButton: false, // 隐藏确认按钮
        beforeClose: undefined, // des:关闭前的回调，会暂停 Dialog 的关闭 | type:Function(done)，done 用于关闭 Dialog
        cancel: undefined, // des:取消回调函数 | type:Function(e)，e模态框本身，点击取消按钮默认关闭模态框，如果配置了该函数则默认不关闭模态框
        submit: undefined, // des:确认回调函数 | type:Function(data, e, view)，data是模态框载入页面的data，e模态框本身，view模态框内嵌的页面
        params: {}, // 传给弹窗加载页面或组件的参数 | Object
      },
      options: {
        view: undefined, // des:view，只能是src/views目录下的vue文件 | type:String
        customClass: undefined, // des:自定义类 | type:String
        title: undefined, // des:标题 | type:String
        width: undefined, // des:宽度 | type:String
        fullscreen: undefined, // des:是否全屏 | type:Boolean
        top: undefined, // des:Dialog CSS 中的 margin-top 值 type:String
        modal: undefined, // des:是否需要遮罩层 | type:Boolean
        modalAppendToBody: undefined, // des:遮罩层是否插入至 body 元素上，若为 false，则遮罩层会插入至 Dialog 的父元素上 | type:Boolean
        appendToBody: undefined, // des:Dialog 自身是否插入至 body 元素上。嵌套的 Dialog 必须指定该属性并赋值为 true | type:Boolean
        lockScroll: undefined, // des:是否在 Dialog 出现时将 body 滚动锁定 | type:Boolean
        closeOnClickModal: undefined, // des:是否可以通过点击 modal 关闭 Dialog | type:Boolean
        closeOnPressEscape: undefined, // des:是否可以通过按下 ESC 关闭 Dialog | type:Boolean
        showClose: undefined, // des:是否显示关闭按钮 | type:Boolean
        center: undefined, // des:是否对头部和底部采用居中布局 | type:Boolean
        destroyOnClose: undefined, // des:关闭时销毁 Dialog 中的元素 | type:Boolean
        cancelButtonName: undefined, // 取消按钮名称
        submitButtonName: undefined, // 确定按钮名称
        hideCancelButton: undefined, // 隐藏取消按钮
        hideSubmitButton: undefined, // 隐藏确认按钮
        beforeClose: undefined, // des:关闭前的回调，会暂停 Dialog 的关闭 | type:Function(done)，done 用于关闭 Dialog
        cancel: undefined, // des:取消回调函数 | type:Function(e)，e模态框本身，点击取消按钮默认关闭模态框，如果配置了该函数则默认不关闭模态框
        submit: undefined, // des:确认回调函数 | type:Function(data, e, view)，data是模态框载入页面的data，e模态框本身，view模态框内嵌的页面
        params: undefined, // 传给弹窗加载页面或组件的参数 | Object
      },
    };
  },
  methods: {
    show(options) {
      // 重置弹框配置
      this.options = this.$utils.deepCopy(this.defaultOptions);
      for (const key in options) {
        this.options[key] = options[key];
      }
      if (options.view) {
        this.component = this.$utils.lazyLoadView(options.view);
      } else if (
        typeof options.view === "object" &&
        options.view._compiled === true
      ) {
        this.component = options.view;
      }
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
    handleCancel() {
      if (this.options.cancel && typeof this.options.cancel === "function") {
        this.options.cancel(this);
      } else {
        this.visible = false;
      }
    },
    handleSubmit(data) {
      if (this.options.submit && typeof this.options.submit === "function") {
        this.options.submit(data, this);
      } else {
        this.visible = false;
      }
    },
  },
};
</script>

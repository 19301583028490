<template>
  <el-form-item
    :label="normalizer.__config__.label"
    :prop="normalizer.__vModel__"
    v-if="!normalizer.__config__.hidden"
  >
    <el-checkbox-group
      v-model="checkedList"
      :disabled="normalizer.disabled"
      :style="normalizer.style"
      @change="handleInput"
    >
      <el-checkbox
        v-for="item in options"
        :key="item.dictValue"
        :label="item.dictValue"
        >{{ item.dictLabel }}</el-checkbox
      >
    </el-checkbox-group>
  </el-form-item>
</template>

<script>
import { getData } from "@/request/system";
export default {
  name: "CheckboxModule",
  props: {
    normalizer: {
      typeof: Object,
      default() {
        return {};
      },
    },
    value: {
      type: Array,
      default: undefined,
    },
  },
  data() {
    return {
      checkedList: [],
      options: [],
    };
  },
  computed: {
    print() {
      return this.$route.query.action === "print";
    },
  },
  watch: {
    value(v) {
      this.checkedList = v;
    },
  },
  methods: {
    handleInput(v) {
      this.$emit("update", {
        [this.normalizer.__vModel__]: v,
      });
    },
  },
  mounted() {
    this.checkedList = this.value || [];
    if (this.print && this.normalizer.printHide) {
      this.checkedList = [];
    }
    this.options = this.normalizer.__slot__.options;
    if (this.normalizer.__config__.dataUrl) {
      getData(this.normalizer.__config__.dataUrl).then((res) => {
        this.options = res.data;
      });
    }
  },
};
</script>

/**
 * API for system
 */
import request from "./index";

/**
 * Get dict options
 * @param {string} name - Dict field name
 */
export function dictOptions(name) {
  return request({
    url: "system/dict/data/type/" + name,
    method: "GET",
  });
}

/**
 * 部门列表
 */
export function listDept(condition) {
  return request({
    url: "system/dept/list",
    method: "GET",
    params: condition,
  });
}

/**
 * 部门详情
 * @param {string} deptId - 部门ID
 */
export function detailsDept(deptId) {
  return request({
    url: "system/dept/" + deptId,
    method: "GET",
  });
}

/**
 * 部门新增
 */
export function addDept(formData) {
  return request({
    url: "system/dept",
    method: "POST",
    data: formData,
  });
}

/**
 * 部门编辑
 */
export function updateDept(formData) {
  return request({
    url: "system/dept",
    method: "PUT",
    data: formData,
  });
}

/**
 * 部门删除
 * @param {string} deptId - 部门ID
 */
export function deleteDept(deptId) {
  return request({
    url: "system/dept/" + deptId,
    method: "DELETE",
  });
}

/**
 * 部门成员列表
 * @param {string} condition.deptId - 部门ID
 * @param {string} condition.month - 月份
 */
export function listDeptMember(condition) {
  return request({
    url: "zjwyArchive/schedulingRules/deptMember",
    method: "POST",
    data: condition,
  });
}

/**
 * 岗位列表
 */
export function listPost(formData) {
  return request({
    url: "zjwyArchive/zjwyPost/list",
    method: "post",
    data: formData,
  });
}

/**
 * 岗位详情
 * @param {string} postId - 岗位ID
 */
export function detailsPost(postId) {
  return request({
    url: "zjwyArchive/zjwyPost/" + postId,
    method: "GET",
  });
}

/**
 * 岗位新增
 */
export function addPost(formData) {
  return request({
    url: "zjwyArchive/zjwyPost",
    method: "POST",
    data: formData,
  });
}

/**
 * 岗位编辑
 */
export function updatePost(formData) {
  return request({
    url: "zjwyArchive/zjwyPost",
    method: "PUT",
    data: formData,
  });
}

/**
 * 岗位删除
 * @param {string} postId - 岗位ID
 */
export function deletePost(postId) {
  return request({
    url: "zjwyArchive/zjwyPost/" + postId,
    method: "DELETE",
  });
}

/**
 * 岗位导出
 */
export function exportPost(formData) {
  return request({
    url: "zjwyArchive/zjwyPost/export",
    method: "POST",
    data: formData,
    responseType: "blob",
  });
}

/**
 * 角色列表
 */
export function listRole(condition) {
  return request({
    url: "system/role/list",
    method: "GET",
    params: condition,
  });
}

/**
 * 角色详情
 * @param {string} roleId - 角色ID
 */
export function detailsRole(roleId) {
  return request({
    url: "system/role/" + roleId,
    method: "GET",
  });
}

/**
 * 角色新增
 */
export function addtRole(formData) {
  return request({
    url: "system/role",
    method: "POST",
    data: formData,
  });
}

/**
 * 角色编辑
 */
export function updateRole(formData) {
  return request({
    url: "system/role",
    method: "PUT",
    data: formData,
  });
}

/**
 * 角色删除
 * @param {string} roleId - 角色ID
 */
export function deletetRole(roleId) {
  return request({
    url: "system/role/" + roleId,
    method: "DELETE",
  });
}

/**
 * 角色导出
 */
export function exportRole(formData) {
  return request({
    url: "system/role/export",
    method: "POST",
    data: formData,
    responseType: "blob",
  });
}

/**
 * 菜单树
 */
export function treeMenu() {
  return request({
    url: "system/menu/treeselect",
    method: "GET",
  });
}

/**
 * 角色菜单树
 */
export function treeMenuForRole(roleId) {
  return request({
    url: "/system/menu/roleMenuTreeselect/" + roleId,
    method: "GET",
  });
}

/**
 * 部门树
 */
export function treeDept() {
  return request({
    url: "system/dept/treeselect",
    method: "GET",
  });
}

/**
 * 角色部门树
 */
export function treeDeptForRole(roleId) {
  return request({
    url: "/system/dept/roleDeptTreeselect/" + roleId,
    method: "GET",
  });
}

/**
 * 资源树
 */
export function treeResource() {
  return request({
    url: "system/sysPlatformResourcesDefinition/treeselect",
    method: "GET",
  });
}

/**
 * 角色资源树
 */
export function treeResourceForRole(roleId) {
  return request({
    url:
      "system/sysPlatformResourcesDefinition/roleResourceTreeselect/" + roleId,
    method: "GET",
  });
}

/**
 * 更新角色数据权限
 */
export function updateRoleDataScope(formData) {
  return request({
    url: "system/role/dataScope",
    method: "PUT",
    data: formData,
  });
}

/**
 * 修改角色状态
 * @param {string} formData.roleId - 角色ID
 * @param {string} formData.status  - 角色状态
 * @returns
 */
export function changeRoleStatus(formData) {
  return request({
    url: "system/role/changeStatus",
    method: "PUT",
    data: formData,
  });
}

/**
 * 更新角色资源权限
 */
export function updateRoleResourceScope(formData) {
  return request({
    url: "system/role/resourceScope",
    method: "PUT",
    data: formData,
  });
}

/**
 * 用户列表
 */
export function listUser(condition) {
  return request({
    url: "system/user/list",
    method: "GET",
    params: condition,
  });
}
/**
 * 用户列表
 */
export function listStaff(data) {
  return request({
    url: "zjwyArchive/staffFile/listStaff",
    method: "post",
    data,
  });
}

/**
 * 用户详情
 * @param {string} userId - 用户ID
 */
export function detailsUser(userId) {
  return request({
    url: "system/user/" + userId,
    method: "GET",
  });
}

/**
 * 用户新增
 */
export function addtUser(formData) {
  return request({
    url: "system/user",
    method: "POST",
    data: formData,
  });
}

/**
 * 用户编辑
 */
export function updateUser(formData) {
  return request({
    url: "system/user",
    method: "PUT",
    data: formData,
  });
}

/**
 * 用户删除
 * @param {string} userId - 用户ID
 */
export function deletetUser(userId) {
  return request({
    url: "system/user/" + userId,
    method: "DELETE",
  });
}

/**
 * 用户导出
 */
export function exportUser(formData) {
  return request({
    url: "system/user/export",
    method: "POST",
    data: formData,
    responseType: "blob",
  });
}

/**
 * 用户修改头像
 * @param {blob} blob
 */
export function uploadAvatar(blob) {
  const form = new FormData();
  form.append("avatarfile", blob);
  return request({
    url: "system/user/profile/avatar",
    method: "POST",
    data: form,
  });
}

/**
 * 用户重置密码
 * @param {string} formData.userId - 用户ID
 * @param {string} formData.password  - 新密码
 * @returns
 */
export function resetUserPwd(formData) {
  return request({
    url: "system/user/resetPwd",
    method: "PUT",
    data: formData,
  });
}

/**
 * 用户修改密码
 * @param {string} data.oldPassword
 * @param {string} data.newPassword
 * @param {string} data.again
 */
export function updatePwd(data) {
  return request({
    url: "system/user/profile/updatePwd",
    method: "PUT",
    params: data,
  });
}

/**
 * 用户修改状态
 * @param {string} formData.userId - 用户ID
 * @param {string} formData.status  - 用户状态
 * @returns
 */
export function changeUserStatus(formData) {
  return request({
    url: "system/user/changeStatus",
    method: "PUT",
    data: formData,
  });
}

/**
 * Upload file
 * @param {file} flie - File
 */
export function uploadFile(file) {
  const form = new FormData();
  form.append("file", file);
  return request({
    url: "file/upload",
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: form,
  });
}

/**
 *
 * @param {string} url - Data url
 */
export function getData(url) {
  return request({
    url: url,
    method: "GET",
  });
}

/**
 * 行政审批查询所有部门
 */
export function listInsideOrganization() {
  return request({
    url: "system/dept/listInsideOrganization",
    method: "GET",
  });
}

/**
 * 下载附件（带原名）
 * @param {string} formData.url - 地址
 * @param {string} formData.filename  - 名称
 * @returns
 */
export function downloadFileNSpecifyFilename(formData) {
  return request({
    url: "file/downloadFileNSpecifyFilename",
    method: "post",
    data: formData,
    responseType: "blob",
  });
}

<template>
  <div class="table-module">
    <!-- <div
      class="box-title-type"
      style="width: 145px"
      v-if="normalizer.__config__.type"
    >
      类型
    </div> -->
    <!-- <el-form-item
      v-if="normalizer.__config__.type"
      label="类型"
      class="box-type"
    >
    </el-form-item> -->
    <el-form-item
      class="box-type"
      v-for="(type, typeIndex) in normalizer.__type__"
      :key="type.value"
      :label="type.name"
      :prop="normalizer.__vModel__"
    >
      <el-table
        :class="type.value === 'type' ? 'box_type_table' : ''"
        v-if="model[type.value]"
        :data="model[type.value]"
        :rules="rules"
        :show-header="normalizer.__extend__.showHeader && typeIndex === 0"
      >
        <!-- <el-table-column
          prop="type"
          label="类型"
          v-if="normalizer.__config__.type"
          width="150px"
          align="center"
        /> -->
        <el-table-column
          type="index"
          label="序号"
          v-if="normalizer.__extend__.showIndex"
          align="center"
          width="50px"
        />
        <el-table-column
          v-for="item in normalizer.__head__"
          :prop="item.value"
          :key="item.vaule"
          :label="item.name"
          align="center"
          :width="item.name === '仓库管理类别' ? '120px' : item.width"
        >
          <template slot-scope="scope">
            <el-input
              v-if="item.type === 'input' && !item.request"
              type="textarea"
              autosize
              :readonly="normalizer.disabled || !hasPermission(item)"
              v-model="scope.row[item.value]"
              @input="handleUpdate(type.value, scope.$index)"
              resize="none"
              @focus="handleFocus(item, scope.$index)"
            />
            <el-checkbox
              v-if="item.type === 'checkbox'"
              v-model="scope.row[item.value]"
              :disabled="normalizer.disabled || !hasPermission(item)"
              true-label="1"
              false-label="0"
            />
            <!--  -->
            <el-form-item>
              <el-select
                v-if="item.type === 'select' && !item.request"
                :multiple="item.multiple"
                v-model="scope.row[item.value]"
                :disabled="normalizer.disabled || !hasPermission(item)"
                placeholder="请选择"
                @change="handleChange(item, scope.$index)"
              >
                <el-option
                  v-for="option in item.list ||
                  dict['zjwy_urgent_purchase_type'] ||
                  dict['zjwy_agency_flow']"
                  :key="option.dictValue"
                  :label="option.dictLabel"
                  :value="option.dictValue"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :rules="rules.input">
              <el-input
                v-if="item.request === 'listAgencyPost'"
                type="textarea"
                autosize
                :readonly="normalizer.disabled || !hasPermission(item)"
                v-model="scope.row[item.value + 'Name']"
                resize="none"
                @focus="handlepost(item, scope.$index)"
              />
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          v-if="
            normalizer.__extend__.editRows &&
            !normalizer.disabled &&
            !print &&
            !purchaseCheck
          "
          width="85px"
          align="center"
        >
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-plus"
              circle
              size="mini"
              @click="handleAdd(scope.$index, type.value)"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              size="mini"
              @click="handleDelete(scope.$index, type.value)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-button
        class="box-menu"
        v-if="
          model[type.value] &&
          model[type.value].length === 0 &&
          type.value != 'type'
        "
        type="primary"
        icon="el-icon-plus"
        circle
        size="mini"
        @click="handleAdd(0, type.value)"
      ></el-button>
    </el-form-item>

    <el-form-item
      v-if="normalizer.__extend__.statistic"
      class="box-statistic"
      label="合计"
    >
      <el-table :data="statisticData" :show-header="false">
        <el-table-column
          width="50px"
          align="center"
          v-if="normalizer.__extend__.showIndex"
        />
        <el-table-column
          v-for="item in normalizer.__head__"
          :prop="item.value"
          :key="item.vaule"
          :label="item.name"
          align="center"
          :width="item.width"
          >name
          <template slot-scope="scope">
            <el-input
              v-if="
                normalizer.__extend__.statisticalField.indexOf(item.value) >= 0
              "
              :readonly="true"
              :value="
                scope.row[item.value] ? scope.row[item.value].toFixed(2) : 0
              "
            />
          </template>
        </el-table-column>
        <el-table-column
          width="85px"
          align="center"
          v-if="
            normalizer.__extend__.editRows &&
            !normalizer.disabled &&
            !purchaseCheck
          "
        />
      </el-table>
    </el-form-item>
    <li-modal ref="liModal" />
  </div>
</template>

<script>
import { dictOptions } from "@/request/system";
export default {
  name: "TableModule",
  props: {
    normalizer: {
      typeof: Object,
      default() {
        return {};
      },
    },
    value: {
      type: Object,
      default() {
        return {};
      },
    },
    purchaseCheck: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rules: {
        input: [
          { required: true, message: "请输入内容", trigger: "blur" },
          // 其他验证规则
        ],
        select: [
          { required: true, message: "请选择内容", trigger: "change" },
          // 其他验证规则
        ],
      },
      model: {},
      dict: {},
      modalConfig: {
        width: "1024px",
        center: true,
        hideCancelButton: true,
        hideSubmitButton: true,
        submit: (data, e) => {
          e.hide();
          this.load();
          // eslint-disable-next-line no-undef
          wx.miniProgram.redirectTo("/pages/index/index");
        },
      },
      formvalue: "",
    };
  },
  computed: {
    statisticData() {
      const statistic = {
        money: 0,
        actualMoney: 0,
      };
      Object.keys(this.model).forEach((type) => {
        this.model[type].forEach((item) => {
          Object.keys(item).forEach((key) => {
            if (statistic[key]) {
              statistic[key] =
                statistic[key] + (item[key] ? Number(item[key]) : 0);
            } else {
              statistic[key] = item[key] ? Number(item[key]) : 0;
            }
          });
        });
      });
      return [statistic];
    },
    print() {
      return this.$route.query.action === "print";
    },
  },
  watch: {},
  methods: {
    handleChange(item, index) {
      this.model.AgentContent[index][item.value].map((element) => {
        let a = item.options.find((res) => res.dictValue == Number(element));

        this.model.AgentContent[index][item.value + "Name"] = this.model
          .AgentContent[index][item.value + "Name"]
          ? this.model.AgentContent[index][item.value + "Name"] +
            "," +
            a.dictLabel
          : a.dictLabel;
      });
      this.$emit("update", { [this.normalizer.__vModel__]: this.model });
    },
    handlepost(item, index) {
      if (this.normalizer.disabled) {
        return;
      }
      this.$refs.liModal.show({
        title: "请选择职务",
        view: "workflow/modules/postAdd.vue",
        ...this.modalConfig,
        submit: (data, e) => {
          // this.model.AgentContent[index][item.value] =
          //   data[item.value].toString();
          // this.model.AgentContent[index][item.value + "Name"] =
          //   data[item.value + "Name"].toString();
          this.$set(
            this.model.AgentContent[index],
            item.value,
            data[item.value]
          );
          this.$set(
            this.model.AgentContent[index],
            item.value + "Name",
            data[item.value + "Name"].toString()
          );
          this.$emit("update", { [this.normalizer.__vModel__]: this.model });
          e.hide();
        },
      });
    },
    handleFocus(item, index) {
      if (item.dialog && !this.normalizer.disabled) {
        this.$refs.liModal.show({
          title: "请选择用户",
          view: "workflow/modules/proposerAdd.vue",
          ...this.modalConfig,
          submit: (data, e) => {
            this.model.AgentContent[index][item.dialog.field1] = data.nickName;
            this.model.AgentContent[index][item.dialog.field2] = data.userName;
            this.$emit("update", { [this.normalizer.__vModel__]: this.model });
            e.hide();
          },
        });
      }
    },
    hasPermission(item) {
      const specific = ["actualPrice", "actualNum", "actualMoney"];
      if (!this.purchaseCheck) {
        return specific.indexOf(item.value) < 0;
      } else {
        return specific.indexOf(item.value) >= 0;
      }
    },
    handleAdd(index, type) {
      const row = {};
      this.normalizer.__head__.forEach((element) => {
        row[element.value] = undefined;
      });
      this.model[type].splice(index + 1, 0, row);
    },
    handleDelete(index, type) {
      this.model[type].splice(index, 1);
    },
    /**
     * 校验正负正数就返回true
     **/

    handleUpdate(key, index) {
      // debugger;
      if (this.model[key][index]["price"]) {
        if (isNaN(this.model[key][index]["price"])) {
          this.$message.warning("必须为数值");
          this.model[key][index]["price"] = undefined;
          return;
        }
      }
      if (this.model[key][index]["num"]) {
        if (isNaN(this.model[key][index]["num"])) {
          this.$message.warning("必须为数值");
          this.model[key][index]["num"] = undefined;
          return;
        }
      }
      /** 写死脚本 */
      if (this.model[key][index]["price"]) {
        if (isNaN(this.model[key][index]["price"])) {
          this.$message.warning("必须为数值");
          this.model[key][index]["price"] = undefined;
          return;
        }
      }
      if (this.model[key][index]["num"]) {
        if (isNaN(this.model[key][index]["num"])) {
          this.$message.warning("必须为数值");
          this.model[key][index]["num"] = undefined;
          return;
        }
      }
      if (this.model[key][index]["price"] && this.model[key][index]["num"]) {
        this.model[key][index]["money"] = (
          this.model[key][index]["price"] * this.model[key][index]["num"]
        ).toFixed(2);
      }
      if (
        this.model[key][index]["actualPrice"] &&
        this.model[key][index]["actualNum"]
      ) {
        this.model[key][index]["actualMoney"] = (
          this.model[key][index]["actualPrice"] *
          this.model[key][index]["actualNum"]
        ).toFixed(2);
      }
      this.$emit("update", { [this.normalizer.__vModel__]: this.model });
    },
    queryDict() {
      this.normalizer.__head__.forEach((item) => {
        if (item.dict) {
          dictOptions(item.dict).then((res) => {
            this.dict = {
              ...this.dict,
              [item.dict]: res.data,
            };
          });
        }
      });
    },
  },
  mounted() {
    this.queryDict();
    if (this.value) {
      this.model = this.value;
    } else {
      const model = {};
      this.normalizer.__type__.forEach((item) => {
        model[item.value] = [];
      });
      this.model = model;
    }
  },
  updated() {
    if (document.getElementsByClassName("box_type_table")[0]) {
      let a = document
        .getElementsByClassName("box_type_table")[0]
        .getElementsByClassName("is-scrolling-none")[0];
      a.style.display = "none";
    }

    if (this.$route.meta.businessId == 90) {
      let label = document
        .getElementsByClassName("table-module")[0]
        .getElementsByClassName("el-form-item__label")[0];
      label.style.fontSize = "16px";
      label.style.color = " #909399";
      label.style.fontWeight = 600;
    }
  },
  // updated() {
  //   let a = document
  //     .getElementsByClassName("box_type_table")[0]
  //     .getElementsByClassName("is-scrolling-none")[0];
  //   a.style.display = "none";
  //   if (this.$route.meta.businessId == 90) {
  //     let label = document
  //       .getElementsByClassName("table-module")[0]
  //       .getElementsByClassName("el-form-item__label")[0];
  //     label.style.fontSize = "16px";
  //     label.style.color = " #909399";
  //     label.style.fontWeight = 600;
  //   }
  // },
};
</script>
<style lang="less" scoped>
.table-module {
  .box-title-type {
    width: 145px;
    display: flex;
    justify-content: center;
    position: absolute;
    line-height: 49px;
    border-bottom: 1px solid #000;
    font-size: 14px;
    color: #606266;
  }
  .box-type {
    position: relative;
    .box-menu {
      position: absolute;
      bottom: 5px;
      right: 10px;
    }
  }

  :deep(.el-table) {
    .el-input__inner {
      width: 160px;
    }
    .el-table__header {
      .el-table__cell {
        border-bottom: 1px solid #333333 !important;
        border-right: 1px solid #333333 !important;
        padding: 2px 0;
        font-size: 16px;
      }
    }
    .el-table__body {
      .el-table__row {
        .el-table__cell {
          border-bottom: 1px solid #333333 !important;
          border-right: 1px solid #333333 !important;
          padding: 2px 0;
          &:last-child {
            border-right: none;
          }

          .cell {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }
    .el-textarea__inner {
      text-align: center;
      color: #333333 !important;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
    }
    .el-textarea__inner::-webkit-scrollbar {
      display: none;
    }
    &.box-statistic {
      background-color: #ff0000;
      .el-table__body {
        .el-table__row {
          .el-table__cell {
            border-top: 1px solid #333333 !important;
          }
        }
      }
    }
  }

  /deep/.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
    border-color: #000 !important;
  }
  // /deep/ .el-checkbox__inner::after {
  //   border: 2px solid #fff;
  // }
}
</style>

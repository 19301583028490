/**
 * Store of auth
 * @description Store of auth
 * @author Zhong Li
 * @version 1.0.0
 * @since v1.0.0
 */
import { getLoginInfo, getRouter } from "@/request/login";
import LayoutAdmin from "@/layout/LayoutAdmin";
import { getWorkflowRoutes } from "@/install/workflow";

import router from "@/router";

const state = {
  loginInfo: undefined,
  routes: [],
};

const mutations = {
  updateLoginInfo: (state, loginInfo) => {
    state.loginInfo = loginInfo;
  },

  clearLoginInfo: (state) => {
    state.loginInfo = undefined;
  },

  updateAvatar: (state, avatar) => {
    state.loginInfo.user.avatar = avatar;
  },

  updateRoutes: (state, routes) => {
    state.routes = routes;
  },
};

const actions = {
  /** Update login info */
  updateLoginInfo: ({ commit }) => {
    return new Promise((resolve, reject) => {
      getLoginInfo()
        .then((res) => {
          commit("updateLoginInfo", res.data);
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /** Clear login info */
  clearLoginInfo: ({ commit }) => {
    commit("clearLoginInfo");
  },

  /**
   * Update avatar
   */
  updateAvatar({ commit }, avatar) {
    commit("updateAvatar", avatar);
  },

  /** Update router map */
  updateRoutes: ({ commit }) => {
    return new Promise((resolve, reject) => {
      getRouter()
        .then((res) => {
          let routeMap = res.data;
          getWorkflowRoutes().then((data) => {
            routeMap.splice(1, 0, data.workflowRoutes);
            // 存在排版申请权限
            if (data.leaveApply) {
              routeMap.forEach((item) => {
                if (item.path === "/scheduling") {
                  item.children.push({
                    name: "WorkflowList" + data.leaveApply.id,
                    path: "/workflow/list/" + data.leaveApply.id,
                    component: "workflow/list.vue",
                    meta: {
                      businessType: data.leaveApply.businessType,
                      title: data.leaveApply.name,
                      group: false,
                      menuType: "m",
                      businessId: data.leaveApply.id, // 业务ID
                      formId: data.leaveApply.formId, // 业务表单ID
                      formVersionId: data.leaveApply.formVersionId, // // 业务表单版本ID
                      processKey: data.leaveApply.processKey, // 流程Key
                    },
                  });
                }
              });
            }

            let routes = parseRoutes(routeMap);
            routes.push({
              path: "*",
              redirect: "/404",
              meta: {
                hidden: true,
              },
            });
            routes.forEach((route) => {
              router.addRoute(route);
            });
            commit("updateRoutes", routes);
            resolve(routes);
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

/**
 * Lazy loading  view
 * @param {string} view - View path
 * @returns {component} - Vue component
 */
function loadView(view) {
  if (process.env.NODE_ENV === "development") {
    return (resolve) => require([`@/views/${view}`], resolve);
  } else {
    return () => import(`@/views/${view}`);
  }
}

/**
 * Parse router
 * @param {array} routes - Router date
 * @returns {array} Lazy loading router date
 */
function parseRoutes(routes) {
  routes.forEach((route) => {
    if (route.component === "LayoutAdmin") {
      route.component = LayoutAdmin;
    } else if (route.meta.menuType === "m") {
      route.component = loadView(route.component);
    } else {
      route.meta.hidden = true;
    }
    if (route.children && route.children.length > 0) {
      parseRoutes(route.children);
    }
  });
  return routes;
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

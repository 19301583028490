/**
 * Main
 * @description Component
 * @author Zhong Li
 * @version 1.0.0
 * @since v1.0.0
 */

import Vue from "vue";

/** Component modules */
import LiModal from "@/components/LiModal/index.js";
import LiView from "@/components/LiView/index.js";
import LiPage from "@/components/LiPage/index.js";
import TlDictTag from "@/components/TlDictTag/index.js";
import TlDeptSelect from "@/components/TlDeptSelect/index.js";
import TlDictSelect from "@/components/TlDictSelect/index.js";
import LiWorkflowForm from "@/components/LiWorkflowForm/index.js";
import TlUserSelect from "@/components/TlUserSelect/index.js";
import LiSchedulingApply from "@/components/LiSchedulingApply/index.js";
import LiPrint from "@/components/LiPrint/index.js";
import TlStaffSelect from "@/components/TlStaffSelect/index.js";
import Pagination from "@/components/Pagination/index.js";
import RightToolbar from "@/components/RightToolbar/index.js";

const components = [
  LiPage,
  LiModal,
  TlDictTag,
  TlDeptSelect,
  LiView,
  TlDictSelect,
  LiWorkflowForm,
  TlUserSelect,
  LiSchedulingApply,
  LiPrint,
  TlStaffSelect,
  Pagination,
  RightToolbar,
];

/** 加载自定义组件 */
components.forEach((component) => {
  Vue.component(component.name, component);
});
